import axios from 'axios'
import { keysToCamelCase } from './helpers'
import { SET_PAGE, SET_TYPES, SET_OPTIONS, ADD_LOADING, REMOVE_LOADING } from './constants'

const baseUrl = process.env.VUE_APP_API_URL.replace(/\/$/, '')

const fetch = (...path) => {
  const query = typeof path.slice(-1)[0] === 'string' ? {} : path.pop()
  const queryString = Object.keys(query)
    .map(key => `${key}=${query[key]}`)
    .join('&')

  return axios(`${baseUrl}/${path.join('/')}${queryString && '?'}${queryString}`).then(({ data }) => data)
}

export default {
  fetchPage: ({ commit, state }, slug) => {
    if (state.pages[slug]) return Promise.resolve(state.pages[slug])

    return fetch('pages', slug).then(({ data, seo = {} }) => {
      const pageData = { seo, ...keysToCamelCase(data) }
      commit(SET_PAGE, [slug, pageData])
    })
  },

  fetchCustomTypes: ({ commit }, reduceData) => {
    commit(ADD_LOADING, 'customTypes')

    return fetch('data').then(data => {
      const customTypes = keysToCamelCase(data)
      commit(SET_TYPES, reduceData(customTypes))
      commit(REMOVE_LOADING, 'customTypes')
    })
  },

  fetchOptions: ({ commit }) => {
    commit(ADD_LOADING, 'options')

    return fetch('options').then(res => {
      const options = res.reduce((optionMap, { key, data }) => {
        return {
          ...optionMap,
          [key]: keysToCamelCase(data)
        }
      }, {})

      commit(SET_OPTIONS, options)
      commit(REMOVE_LOADING, 'options')
    })
  }
}
