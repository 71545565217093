import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export const store = new Vuex.Store({
  state: {
    imageModal: null
  },
  getters: {
    trainingsBySlug: ({ nova }) => {
      const { trainings } = nova.customTypes
      return Object.values(trainings).reduce((slugMap, training) => ({ ...slugMap, [training.slug]: training }), {})
    }
  }
})

export const reduceNovaData = data => {
  const { themes } = data
  const themeList = Object.values(themes)

  const childThemesByParent = themeList.reduce((childMap, theme) => {
    const { parentThemeId } = theme
    if (!parentThemeId) return childMap
    const siblings = childMap[parentThemeId] || []
    return { ...childMap, [parentThemeId]: [...siblings, theme] }
  }, {})

  const mappedThemes = themeList
    .filter(({ parentThemeId }) => !parentThemeId)
    .reduce((themeMap, parentTheme) => {
      const { id } = parentTheme

      return {
        ...themeMap,
        [id]: { ...parentTheme, children: childThemesByParent[id] || [] }
      }
    }, {})

  return { ...data, themes: mappedThemes }
}
