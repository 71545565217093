<template>
  <div class="filter button -filter">
    <button type="button" class="filter__title" @click="toggleVisibility" :class="{'-open' : isOpened}">
      {{ selectedLabel }}
    </button>

    <div v-if="isOpened" id="v-model-radiobutton" @click="toggleVisibility">
      <RouterLink :to="getRouterLink()" class="filter__label" :class="{'-green': selectedLabel === defaultLabel}">
        {{ defaultLabel }}
      </RouterLink>

      <RouterLink v-for="(label, value) in options" :key="value" :to="getRouterLink(value)" class="filter__label" :class="{'-green': selectedLabel === label}">
        <template v-if="isOwner">
          <div class="filter__label-owner" :class="{'-other' : value === 'other'}">
          </div>
        </template>
        {{ label }}
      </RouterLink>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FilterRadio',
  props: {
    param: { type: String, required: true },
    options: { type: Object, default: Object },
    defaultLabel: { type: String, default: 'Tous' },
    hasIcon: { type: Boolean, default: false }
  },
  data() {
    return {
      isOpened: false
    }
  },
  computed: {
    selectedLabel() {
      if (!this.$route.query[this.param]) return this.defaultLabel
      return this.options[this.$route.query[this.param]]
    },
    isOwner() {
      return this.param === 'owner'
    }
  },
  methods: {
    toggleVisibility() {
      this.isOpened = !this.isOpened
    },
    getRouterLink(value = false) {
      const currentQuery = this.$route.query

      const query =
        value && currentQuery[this.param] !== value
          ? this.setQueryKey(currentQuery, this.param, value)
          : this.unsetQueryKey(currentQuery, this.param)

      return { ...this.$route, query }
    },
    unsetQueryKey(query, key) {
      return Object.keys(query)
        .filter(qKey => qKey !== key)
        .reduce((queryMap, qKey) => ({ ...queryMap, [qKey]: query[qKey] }), {})
    },
    setQueryKey(query, key, value) {
      return { ...query, [key]: value }
    }
  }
}
</script>
