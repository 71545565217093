<template>
  <div>
    <div v-if="slides.length > 1" class="row -end -mobile swiper__controls">
      <div class="slider__panel">
          <span class="active">{{ swiperIndex + 1 }}</span>
        <span class="total">{{ slides.length }}</span>
      </div>

      <div>
        <button @click="swipeTo(1)" class="swiper__controls-arrow"><Icon id="back" class="back" /></button>
        <button @click="swipeTo()" class="swiper__controls-arrow"><Icon id="next" /></button>
      </div>
    </div>

    <div class="swiper__wrapper">
      <figure
          v-if="isEponge"
          class="slider__content__badge swiper__badge"
          :style="getBackgroundImage($nova.options.general.badge)"
      />
      <div ref="swiper" v-swiper:swiper="swiperMenuOptions" class="swiper" @slideChangeTransitionEnd="swipe">

        <div class="swiper-wrapper">

          <div class="swiper-slide" v-for="(slide, key) in loop" :key="key" >
            <img src="/images/player.png" alt="player" width="132" height="132" class="video__play -project" v-if="slide.video"/>
            <figure :class="['swiper__item']" :style="{backgroundImage: `url(${slide.image ? slide.image : ''})`}" @click="openImage(key)"/>
          </div>
        </div>
      </div>
    </div>

    <transition name="fade">
      <div v-if="selectedSlide !== null" class="video__player" @click.stop="selectedSlide = null">
        <div class="video__player-content -image" @click.stop>
          <div class="row -align-center" style="width: 100%">
            <div class="video__player-content-btn -desktop" :style="{transform: `rotate(180deg)`}" @click="prev()">
              <Icon id="next" />
            </div>
            <transition name="fade" mode="out-in" appear>
              <iframe v-if="slides[selectedSlide].video"
                  :src="`https://www.youtube.com/embed/${videoId}?autoplay=1`"
                  frameborder="0"
                  allow="autoplay"
                  class="video__player-iframe">
              </iframe>
              <div v-else :style="{backgroundImage: `url(${slides[selectedSlide].image})`}" class="video__player-content-image"/>
            </transition>
            <div class="row -mobile -center">
              <div class="video__player-content-btn -mobile" :style="{transform: `rotate(180deg)`}" @click="prev()">
                <Icon id="next" />
              </div>
              <div class="video__player-content-btn" @click="next()">
                <Icon id="next"/>
              </div>
            </div>
          </div>
          <button class="button  -nom video__player-close" :class="{'-corner-right' : $device.mobile, '-corner-bottom-left': !$device.mobile}" @click="selectedSlide = null">Fermer</button>
        </div>
      </div>
    </transition>
  </div>

</template>

<script>
import { directive } from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'
import ImageModal from "@/components/partials/ImageModal.vue";
export default {
  name: "SwiperProject",
  components: {ImageModal},
  props: {
    isEponge: { type: Number, default: null },
    slides: { type: Array, default: Array },
  },
  directives: {
    swiper: directive
  },
  data () {
    return {
      swiperMenuOptions: {
        slidesPerView: 'auto',
        preloadImages: true,
      },
      swiperIndex: 0,
      selectedSlide: null
    }
  },
  mounted() {
    setTimeout(() => {
      console.log(this.swiper)
    },200)
  },
  computed: {
    videoId() {
      if(this.slides[this.selectedSlide].video) return this.slides[this.selectedSlide].video.match(/v=([^&]+)/)[1]
      return null
    },
    active() {
      if(this.swiper) return this.swiper.realIndex
      else return 1
    },
    loop() {
      if(this.slides.length >= 3) return [...this.slides, ...this.slides]
      else return this.slides
    }
  },
  methods: {
    swipe(e) {
      if(e.realIndex + 1 > this.slides.length) this.swiperIndex = e.realIndex - this.slides.length
      else this.swiperIndex = e.realIndex
    },
    swipeTo(prev) {
      if(prev) this.swiper.slidePrev()
      else this.swiper.slideNext()
    },
    openImage(slide) {
      this.selectedSlide = slide
    },
    getBackgroundImage(src) {
      return {backgroundImage: `url(${src})`}
    },
    next() {
      if(this.selectedSlide + 1 < this.slides.length) this.selectedSlide += 1;
      else this.selectedSlide = 0
    },
    prev() {
      if(this.selectedSlide === 0) this.selectedSlide = this.slides.length - 1
      else this.selectedSlide -= 1
    }
  }
}
</script>
