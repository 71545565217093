<template>
  <transition name="fade">
    <div v-if="image" class="video__player" @click.stop="close()">
      <div class="video__player-content -image" @click.stop>
        <img :src="image" class="video__player-content-image">
        <button class="button -corner-bottom-left" @click="close()">Fermer</button>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "ImageModal",
  computed: {
    image() {
      return this.$store.state.imageModal
    }
  },
  methods: {
    close() {
      this.$store.state.imageModal = null
    }
  }
}
</script>
