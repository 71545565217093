import Vue from 'vue'
import VueRouter from 'vue-router'

import {
  Home,
  NotFound,
  Involve,
  About,
  Accompaniment,
  Themes,
  Theme,
  Trainings,
  Training,
  Projects,
  Project
} from '@/views'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/s-impliquer',
    name: 'involve',
    component: Involve
  },
  {
    path: '/a-propos',
    name: 'program',
    component: About
  },
  {
    path: '/accompagnement',
    name: 'accompaniment',
    component: Accompaniment
  },
  {
    path: '/boite-a-outils',
    name: 'themes',
    component: Themes
  },
  {
    path: '/boite-a-outils/:themeSlug/:childThemeSlug?',
    name: 'theme',
    component: Theme,
    props: true
  },
  {
    path: '/formations',
    name: 'trainings',
    component: Trainings
  },
  {
    path: '/formations/:trainingSlug',
    name: 'training',
    component: Training,
    props: true
  },
  {
    path: '/projets',
    name: 'projects',
    component: Projects
  },
  {
    path: '/projets/:projectSlug',
    name: 'project',
    component: Project,
    props: true
  },
  {
    path: '/404',
    name: 'not-found',
    component: NotFound
  },
  {
    path: '*',
    name: 'catch-all',
    component: NotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: '-active',
  routes
})

export default router
