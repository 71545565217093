<template>
  <div v-if="!$page" />

  <div v-else class="page">
    <header class="header -column">
      <h1 v-scroll:reveal="{ delay: 550 }" class="header__subtitle">{{ $page.header.hat }}</h1>
      <p v-scroll:reveal="{ delay: 600 }" class="header__title">{{ $page.header.title }}</p>
    </header>

    <section v-scroll:reveal="{ delay: 700, offset: 0 }" class="section -first -flower">
      <h2 v-scroll:reveal="{ delay: 700, offset: 0 }" class="section__title">{{ $page.services.title }}</h2>
      <div class="row">
        <RawHtml :html="$page.services.description" class="row__item -span-9"/>
        <img :src="$page.services.image" alt="" class="row__item -skip-1 accompaniment__badge" />
      </div>
      <RouterLink :to="$page.services.link" class="section__link">
        <span>{{ $page.services.label }}</span>
        <Icon id="leaves" class="button__icon" />
      </RouterLink>
    </section>

    <section id="services" class="section -first">
      <div class="accompaniment__services">
        <div v-for="(service, serviceKey) in $page.services.items" :key="serviceKey" class="accompaniment__service">
          <h3 class="section__subtitle">{{ service.title }}</h3>
          <div class="accompaniment__services">
            <div v-for="(subService, subServiceKey) in service.items" :key="subServiceKey" class="accompaniment__service"  :class="{'-child': subServiceKey === 1}">
              <h4 v-if="subService.title" class="accompaniment__subtitle">{{ subService.title }}</h4>
              <RawHtml :html="subService.description" />
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="partenaires" class="section -first row__item -span-10">
      <h2 v-scroll:reveal="{ delay: 100 }" class="section__title">{{ $page.partners.title }}</h2>
      <WrapHtml v-scroll:reveal="{ delay: 100 }">{{ $page.partners.description }}</WrapHtml>
      <p class="accompaniment__link">
        <RouterLink v-scroll:reveal="{ delay: 100 }" :to="$page.partners.link" class="section__link ">
          {{ $page.partners.label }}
          <Icon id="leaves" />
        </RouterLink>
      </p>
    </section>

    <CtaContact
      v-scroll:reveal="{ delay: 100 }"
      :title="$page.contact.title"
      :subtitle="$page.contact.description"
      :link-label="$page.contact.label"
      route-name="involve"
    />
  </div>
</template>

<script>
import { PageMetaMixin } from '@/mixins'
import { CtaContact } from '@/components/partials'

export default {
  name: 'Accompaniment',
  novaPage: 'services',
  components: { CtaContact },
  mixins: [PageMetaMixin]
}
</script>
