<template>
  <div v-if="!$page || !theme"/>
  <div v-else :key="theme.id" class="page">
    <header class="header -column -wide">
      <div class="theme__nav">
        <nav>
          <RouterLink v-scroll:reveal :to="{ name: 'themes' }" class="header__breadcrumb">
            {{ $page.labels.title }}
          </RouterLink>

          <RouterLink
              v-if="!isParentTheme"
              v-scroll:reveal="{ delay: 75 }"
              :to="{ name: 'theme', params: { themeSlug: parentTheme.slug } }"
              class="header__breadcrumb"
          >
            {{ parentTheme.title }}
          </RouterLink>

          <span v-scroll:reveal="{ delay: 150 }" class="header__breadcrumb -current">{{ theme.title }}</span>
        </nav>

        <h1 v-scroll:reveal="{ delay: 600 }" class="header__title theme__nav__title">{{ theme.title }}</h1>
      </div>
    </header>

    <section v-scroll:reveal="{ delay: 800, offset: 0 }" class="section -mid-top row">
      <div class="section__content theme">
        <RawHtml class="row__item -span-9 -flower" :html="theme.content"/>

        <nav  class="row__item -span-3 theme__aside">
          <button @click="scrollToTools" class="button -link -flat -corner-right theme__aside-button" v-if="tools.length">
            {{ $page.labels.viewTools }}
          </button>
          <template v-if="childThemeList.length">
            <h2 class="section__subtitle -sm">
              {{ childThemeSlug ? 'Thématique principale et autre sous-thématiques' : $page.labels.subThemes }}
            </h2>

            <RouterLink
                v-if="childThemeSlug"
                :to="getParentThemeLink()"
                class="theme__child -parent">
              {{ parentTheme.title }}
              <Icon id="leaves" class="theme__aside-leaves"/>
            </RouterLink>

            <RouterLink
                v-for="child in childThemeList"
                :key="child.id"
                :to="getChildThemeLink(child)"
                class="theme__child"
            >
              {{ child.title }}
              <Icon id="leaves" class="theme__aside-leaves"/>
            </RouterLink>
          </template>
        </nav>
      </div>
    </section>

    <section id="outils" class="section -mid-top" ref="tools" v-if="tools.length">
      <h2 class="section__title">{{ $page.labels.associatedTools }}</h2>
      <div v-if="tools.length" class="theme__tools">
        <ul v-scroll:reveal="{ delay: 550 }" class="theme__tools__list">
          <li
              v-for="(item, idx) in firstTools"
              :key="item.id"
              v-scroll:reveal="{ delay: idx * 75 }"
              class="card -training"
          >

            <a :href="getHref(item)" class="row" target="_blank" rel="noopener">
              <span class="bubble -tool theme__tools__item-icon" :style="{backgroundColor: getToolColor(item)}"><Icon :id="getToolIcon(item)"/></span>
              <div class="card__content">
                <div class="row -spread -align-start">
                  <h2 class="card__title">{{ item.title }}</h2>
                  <div class="card__medias__link link -after">
                    <span>Consulter </span>
                    <Icon id="leaves"/>
                  </div>
                </div>
                <p>{{ item.description }}</p>
              </div>
            </a>
          </li>
        </ul>

        <DropDown :is-open="isToolsListOpened">
          <ul v-scroll:reveal="{ delay: 550 }" class="theme__tools__list">
            <li
                v-for="(item) in toolsList"
                :key="item.id"
                class="card -training"
            >

              <a :href="getHref(item)" class="row" target="_blank" rel="noopener">
                <span class="bubble -tool theme__tools__item-icon" :style="{backgroundColor: getToolColor(item)}"><Icon :id="getToolIcon(item)"/></span>
                <div class="card__content">
                  <div class="row -spread -align-start">
                    <h2 class="card__title">{{ item.title }}</h2>
                    <div class="card__medias__link link -after">
                      <span>Consulter </span>
                      <Icon id="leaves"/>
                    </div>
                  </div>
                  <p>{{ item.description }}</p>
                </div>
              </a>
            </li>
          </ul>
        </DropDown>

        <button
            v-if="isToggleButtonVisible"
            v-scroll:reveal="{ delay: 600 }"
            class="button -alt -corner-left theme__tools__more"
            @click="toggleToolsList"
        >
          <span class="theme__tools__more-icon"> {{ isToolsListOpened ? '-' : '+' }} </span>
          {{ $page.labels.moreTools }}
        </button>
      </div>
    </section>

    <section v-if="trainings.length" class="section -mid-top theme__trainings">
      <h2 v-scroll:reveal="{ delay: 100 }" class="section__title">{{ $page.labels.associatedTrainings }}</h2>

      <ul class="row -mobile theme__trainings__list">
        <CardTraining
            v-for="(item, idx) in trainings"
            :key="item.key"
            theme
            v-scroll:reveal="{ delay: idx * 75 }"
            :item-id="item.id"
            class="theme__trainings__item"
            :labels="{ ended: 'Terminé', info: 'Plus d’info', offered_by: 'Offert par' }"
        />
      </ul>
    </section>

    <CtaContact
        v-scroll:reveal="{ delay: 100 }"
        :title="contactTitle"
        :subtitle="contactDescription"
        :link-label="contactLink"
        :to="contactTo"
    />
  </div>
</template>

<script>
import {PageMetaMixin} from '@/mixins'
import {CardTraining, CtaContact} from '@/components/partials'
import {supportedExtension} from '@/constants'
import DropDown from "@/components/global/DropDown.vue";

export default {
  name: 'Theme',
  components: {DropDown, CtaContact, CardTraining},
  novaPage: 'theme',
  mixins: [PageMetaMixin],
  props: {
    themeSlug: {type: String, required: true},
    childThemeSlug: {type: String, default: null}
  },
  metaInfo() {
    if (this.theme) {
      const {seo = {}} = this.$page || {}
      const title = this.theme.seoTitle || this.theme.title
      const description = this.theme.seoDescription || seo.description
      const image = this.theme.seoImage || this.theme.image

      return {
        title: `${title} | Boîte-à-outils`,
        meta: [
          {name: 'description', content: description},
          {
            property: 'og:title',
            content: `${title} | Boîte-à-outils | Ville éponge`
          },
          {property: 'og:description', content: description},
          {property: 'og:image', content: image}
        ]
      }
    }
  },
  data() {
    return {
      isToggleButtonVisible: true,
      isToolsListOpened: false
    }
  },
  computed: {
    contactDescription() {
      return this.theme.cta ? this.theme.cta.description : this.$page.contact.description
    },
    contactTitle() {
      return this.theme.cta ? this.theme.cta.title : this.$page.contact.title
    },
    contactLink() {
      return this.theme.cta ? this.theme.cta.label ? this.theme.cta.label : this.$page.contact.label : this.$page.contact.label
    },
    contactTo() {
      return this.theme.cta ? this.theme.cta.link ? this.theme.cta.link : this.$page.contact.link : this.$page.contact.link
    },
    parentTheme() {
      return Object.values(this.$nova.themes).find(({slug}) => slug.includes(this.themeSlug))
    },
    theme() {
      const theme = Object.values(this.$nova.themes).find(({slug}) => slug.includes(this.themeSlug))
      if (this.childThemeSlug) {
        return theme.children.find(({slug}) => slug.includes(this.childThemeSlug))
      }
      return theme
    },
    childThemeList() {
      if (this.childThemeSlug) {
        return [...this.parentTheme.children].sort((a, b) => {
          if (a.slug === this.childThemeSlug) return -1
          else if (b.slug === this.childThemeSlug) return 1;
          return 0;
        })
      }
      return this.parentTheme.children
    },
    isParentTheme() {
      return !this.childThemeSlug
    },
    relatedThemes() {
      const {relatedThemes = []} = this.theme || {}
      return relatedThemes.map(themeId => this.$nova.themes[themeId])
    },
    tools() {
      const {tools = []} = this.theme || {}
      const relatedTools = tools.map(toolId => this.$nova.tools[toolId]).sort((a, b) => a.title[0].localeCompare(b.title[0]));
      this.setToggle(relatedTools.length)
      return relatedTools
    },
    toolsList() {
      return this.tools.slice(3, this.tools.length)
    },
    firstTools() {
      return this.tools.slice(0,3)
    },
    trainings() {
      const {trainings = []} = this.theme || {}
      return trainings.map(trainingId => this.$nova.trainings[trainingId])
    }
  },
  beforeMount() {
    if (!this.theme) this.$router.replace({name: 'not-found'})
  },
  methods: {
    getHref(item) {
      return item.isExternal ? item.url : item.file
    },
    getToolIcon(item) {
      if (!item.isExternal || item.isFile) {
        const extension = item.isFile ? item.url.split('.').pop() : item.file.split('.').pop()
        return supportedExtension.indexOf(extension) !== -1 ? `tool-${extension}` : 'tool-doc'
      }
      return 'tool-link'
    },
    getToolColor(item){
      if(item.isExternal) return '#007358'
      const extension = item.isFile ? item.url.split('.').pop() : item.file.split('.').pop()
      if(extension === 'pdf') return '#0BBBA4'
      else return '#FF6E5F'
    },
    setToggle(toolCount) {
      if (toolCount < 4 || !toolCount) {
        this.isToggleButtonVisible = false
      } else {
        this.isToggleButtonVisible = true
      }
    },
    toggleToolsList() {
      this.isToolsListOpened = !this.isToolsListOpened
    },
    getChildThemeLink({slug}) {
      return {...this.$route, params: { childThemeSlug: slug}, query: ''}
    },
    getParentThemeLink() {
      return {...this.$route, params: {themeSlug: this.parentTheme.slug}, query: ''}
    },
    scrollToTools() {
      const tools = this.$refs['tools']
      tools.scrollIntoView({behavior: "smooth", block: 'start'})
    }
  }
}
</script>
