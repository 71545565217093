$
<template>
  <section class="section -mid-top -marg cta-contact">
    <h2 class="section__title">{{ title }}</h2>
    <WrapHtml class="section__legend">{{ subtitle }}</WrapHtml>

    <div class="cta-contact__actions">
      <RouterLink class="button -link" :to="openPanelLink">Nous contacter</RouterLink>
      <RouterLink v-if="linkLabel" :to="to ? to : { name: routeName }" class="section__link cta-contact__link">
        {{ linkLabel }}
        <Icon id="leaves"/>
      </RouterLink>
    </div>
  </section>
</template>

<script>
export default {
  name: 'CtaCtontact',
  props: {
    title: { type: String, default: null },
    subtitle: { type: String, default: null },
    linkLabel: { type: String, default: null },
    to: {type: String, default: null},
    routeName: { type: String, default: 'accompaniment' }
  },
  computed: {
    openPanelLink() {
      return {
        name: this.$route.name,
        query: {
          ...this.$route.query,
          contact: true
        }
      }
    }
  }
}
</script>
