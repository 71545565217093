<template functional>
  <svg ref="svgRef" class="icon" :class="[data.class, data.staticClass]">
    <use :xlink:href="`#${props.id}`" />
  </svg>
</template>

<script>
export default {
  name: 'Icon',
  props: {
    id: { type: String, required: true }
  }
}
</script>

<style>
.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  fill: currentColor;
  text-decoration: inherit;
}
</style>
