<template>
  <div :class="{ modal: isFullScreen }" @click="onClickOutside">
    <div :class="['slider', { '-fullscreen': isFullScreen }]" @click.stop>
      <div class="slider__content">
        <figure
          v-if="isEponge"
          class="slider__content__badge"
          :style="getBackgroundImage($nova.options.general.badge)"
        />
        <figure :class="['slider__content__item', '-image', { '-fullscreen': isFullScreen }]" :style="currentSlide" />
        <button
          v-if="!isFullScreen && !$device.mobile"
          class="slider__fullscreen"
          aria-label="Voir en plein écran"
          @click="isFullScreen = !isFullScreen"
        >
          <Icon id="expand" />
        </button>
      </div>

      </div>

      <button v-if="isFullScreen" class="slider__close" @click="isFullScreen = !isFullScreen">
        <Icon id="close" />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SliderProject',
  props: {
    isEponge: { type: Number, default: null },
    slides: { type: Array, default: Array },
    itemClass: { type: [String, Array, Object], default: Array }
  },
  data() {
    return {
      currentIndex: 0,
      isFullScreen: false
    }
  },
  computed: {
    currentSlide() {
      return { backgroundImage: `url('${this.slides[this.currentIndex]}')` }
    }
  },
  watch: {
    isFullScreen() {
      this.isFullScreen ? this.$scroll.prevent() : this.$scroll.restore()
    }
  },
  methods: {
    getBackgroundImage(url) {
      return `background-image: url('${url}')`
    },
    onClickNext() {
      this.currentIndex = (this.currentIndex + 1) % this.slides.length
    },
    onClickPrev() {
      const index = this.currentIndex || this.slides.length
      this.currentIndex = index - 1
    },
    onClickOutside() {
      this.isFullScreen = false
    }
  }
}
</script>
