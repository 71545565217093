import Element from './Element'
import Icon from './Icon.vue'
import IconRouterLink from './IconRouterLink.vue'
import DropDown from './DropDown.vue'
import RawHtml from './RawHtml.vue'
import WrapHtml from './WrapHtml'
import ScopedLink from './ScopedLink.vue'

const globals = [Element, Icon, IconRouterLink, DropDown, RawHtml, WrapHtml, ScopedLink]

export { Element, Icon, IconRouterLink, DropDown, RawHtml, WrapHtml, ScopedLink }

export default Vue => globals.forEach(component => Vue.component(component.name, component))
