<template>
  <Element :tag="tag" class="raw-html" id="raw">
    <slot/>
  </Element>
</template>

<script>
import sanitizeHtml from 'sanitize-html'

export default {
  name: 'RawHtml',
  props: {
    tag: { type: String, default: 'div' },
    html: { type: [String, Object], default: Object },
    targetBlankLinks: { type: Boolean, default: false }
  },
  data() {
    return {
      replace: [[/(<br\s?\/?>)+/g, '<br>']]
    }
  },
  computed: {
    htmlContent() {
      const { before = null, after = null } = typeof this.html === 'string' ? { after: this.html } : this.html
      return { afterbegin: before, beforeend: after }
    }
  },
  watch: {
    htmlContent: {
      immediate: true,
      handler: 'renderHTML'
    }
  },
  methods: {
    renderHTML() {
      this.$nextTick(() => {
        const { default: slots = [] } = this.$slots
        const nodes = slots.map(({ elm }) => elm.cloneNode(true))
        this.$el.innerHTML = ''
        nodes.forEach(node => this.$el.appendChild(node))

        Object.keys(this.htmlContent).forEach(position => {
          const content = this.htmlContent[position]
          if (!content) return

          const renderNode = document.createElement('div')

          renderNode.innerHTML = sanitizeHtml(this.cleanUpRaw(content), {
            allowedTags: [...sanitizeHtml.defaults.allowedTags, 'img'],
            allowedAttributes: {
              ...sanitizeHtml.defaults.allowedAttributes,
              a: ['class', 'href', 'target'],
              img: ['src', 'width', 'height']
            },
            allowedClasses: ['leaves']
          })

          Array.from(renderNode.querySelectorAll('img')).forEach(img => {
            const { width, height } = img
            if (width) img.style.width = `${width}px`
            if (height) img.style.height = `${height}px`
          })

          this.$el.insertAdjacentHTML(position, renderNode.innerHTML)
          if (this.targetBlankLinks) this.convertExternalLinks()
          this.listenToImageClick()
          this.setLeaves()
        })

      })
    },
    convertExternalLinks() {
      Array.from(this.$el.querySelectorAll('a[href^="http"]')).forEach(externalLink => {
        externalLink.target = '_blank'
        externalLink.rel = 'noopener'
      })
    },
    cleanUpRaw(content) {
      return this.replace.reduce((str, grep) => {
        const [match, sub = ''] = Array.isArray(grep) ? grep : [grep]
        return str.replace(match, sub)
      }, content)
    },
    listenToImageClick() {
      this.$nextTick(() => {
        this.$el.querySelectorAll('img').forEach(img => {
          img.addEventListener('click', e => {
            const src = e.target.getAttribute('src');
            this.$store.state.imageModal = src
          })
        })
      })
    },
    setLeaves() {
      this.$nextTick(() => {
        this.$el.querySelectorAll('a').forEach(el => {
          if(el.className === 'leaves') {
            el.insertAdjacentHTML('afterend',`<?xml version="1.0" encoding="UTF-8" standalone="no"?><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="width: 19px; height: 17px; margin-bottom: -0.17rem;" viewBox="0 0 512 441" width="512pt" height="441pt"><defs><clipPath id="_clipPath_rn9vDUz51Q0W8wo6FqiwWu1Xdj7660L2"><rect width="512" height="441"/></clipPath></defs><g clip-path="url(#_clipPath_rn9vDUz51Q0W8wo6FqiwWu1Xdj7660L2)"><g><path d=" M 235.144 235.091 C 295.27 175.073 320.785 88.619 302.882 5.572 C 219.826 -12.242 133.402 13.271 73.336 73.336 C 13.271 133.402 -12.242 219.826 5.572 302.882 C 88.645 320.784 175.122 295.248 235.144 235.091 L 235.144 235.091 Z " fill="rgb(0,115,88)"/><path d=" M 258.741 440.669 C 156.416 440.76 61.054 388.859 5.572 302.882 C 61.138 216.972 156.473 165.096 258.786 165.096 C 361.1 165.096 456.434 216.972 512 302.882 C 456.495 388.881 361.095 440.783 258.741 440.669 L 258.741 440.669 Z " fill="rgb(0,187,164)"/></g></g></svg>`)
          }
        })
      })
    }
  }
}
</script>
