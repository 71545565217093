<template>
  <div class="input">
    <label v-if="label" :for="fieldMeta.id" class="input__label">{{ label }}</label>

    <textarea
      v-if="textarea"
      :id="fieldMeta.id"
      :value="value"
      :name="fieldMeta.name"
      :class="fieldClassName"
      @input="onInput"
      @blur="$emit('validate')"
    />

    <input
      v-else
      :id="fieldMeta.id"
      :ref="fieldMeta.name"
      :name="fieldMeta.name"
      type="text"
      :value="value"
      :class="fieldClassName"
      @input="onInput"
      @blur="$emit('validate')"
    />
    <p v-if="error" class="input__error"><Icon id="warning" class="input__warning" />{{ errorMessage }}</p>
  </div>
</template>

<script>
import randomId from 'random-entities/id'

export default {
  name: 'InputText',
  props: {
    id: { type: String, default: null },
    name: { type: String, default: null },
    label: { type: String, default: null },
    value: { type: String, default: null },
    inputClass: { type: [String, Array, Object], default: Array },
    textarea: { type: Boolean, default: false },
    error: { type: Boolean, default: false },
    errorMessage: { type: String, default: null },
    hasFocus: { type: Boolean, default: false }
  },
  data() {
    const generatedId = randomId()
    const id = this.id || generatedId

    return {
      fieldMeta: { id, name: this.name || id }
    }
  },
  computed: {
    fieldClassName() {
      return ['input__field', this.inputClass, { '-error': this.error, '-textarea': this.textarea }]
    }
  },
  mounted() {
    if (this.hasFocus)
      this.$nextTick(function () {
        const ref = document.getElementById(this.fieldMeta.id)
        ref.focus()
      })
  },
  methods: {
    onInput({ target }) {
      this.$emit('input', target.value)
    }
  }
}
</script>
