import generateUid from 'random-entities/id'

import scrollHubFactory from './ScrollHub'
import parallax from './parallax'
import reveal from './reveal'

const addPerformanceStyles = () => {
  const style = document.createElement('style')
  style.innerHTML = '[data-scroll-id] { backface-visibility: hidden }'
  document.head.appendChild(style)
}

export default (Vue, { scrollingElement, scrollEventTarget, ...options } = {}) => {
  addPerformanceStyles()

  const ScrollHub = scrollHubFactory(Vue)

  const modules = {
    parallax: parallax(ScrollHub),
    reveal: reveal(ScrollHub)
  }

  if (options) Object.values(modules).forEach(({ setOptions }) => (setOptions ? setOptions(options) : null))
  if (scrollingElement) ScrollHub.init(scrollingElement, scrollEventTarget)

  Vue.directive('scroll', {
    bind(node, { arg, value, modifiers }) {
      if (!modules[arg] || !modules[arg].init) return
      modules[arg].init({ uid: generateUid(), node, value, modifiers })
    },
    inserted(node, { arg, value, modifiers }) {
      if (!modules[arg] || !modules[arg].add) return
      const uid = node.dataset.scrollId || generateUid()
      modules[arg].add({ uid, node, value, modifiers })
    },
    unbind(node, { arg }) {
      if (!modules[arg] || !modules[arg].remove) return
      modules[arg].remove(node.dataset.scrollId)
    }
  })

  Vue.prototype.$scrollHub = {
    setScrollingElement: ScrollHub.init,
    triggerUpdate: ScrollHub.triggerUpdate,
    unbind: ScrollHub.unbind,
    bind(handler) {
      const uid = generateUid()
      ScrollHub.bind(uid, handler)
      return uid
    }
  }
}
