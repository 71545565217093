<template>
  <figure class="mockup">
    <div class="mockup__container">
      <div class="mockup__taskbar">
        <span class="mockup__taskbar-button" />
        <span class="mockup__taskbar-button" />
        <span class="mockup__taskbar-button" />
      </div>

      <div class="mockup__body">
        <div class="mockup__banner">VOTRE SITE</div>

        <div class="mockup__row">
          <div class="mockup__col">
            <div class="mockup__line" />
            <div class="mockup__line" />
            <div class="mockup__line" />
          </div>

          <div class="mockup__col mockup__block" />
        </div>

        <div class="mockup__row">
          <div class="mockup__col" />

          <div class="mockup__col -row">
            <div class="mockup__block" />
            <div class="mockup__block" />
          </div>
        </div>
      </div>
    </div>

    <figure class="mockup__badge" :style="getBackgroundImage(badgePartner)" />
  </figure>
</template>

<script>
export default {
  name: 'Mockup',
  computed: {
    badgePartner() {
      const { partner = [] } = this.$nova.options.general
      return partner[0]
    }
  },
  methods: {
    getBackgroundImage(url) {
      return `background-image: url('${url}')`
    }
  }
}
</script>
