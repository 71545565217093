<template>
  <transition appear name="sidenav">
    <div v-if="isVisible" class="sidenav">
      <nav class="sidenav__content">
        <template v-if="!$device.tablet">
          <RouterLink v-scroll:reveal="{ delay: 275, offset: 0 }" :to="{ name: 'program' }" class="sidenav__title">
            À propos
          </RouterLink>

          <RouterLink
            v-scroll:reveal="{ delay: 275, offset: 0 }"
            :to="{ name: 'accompaniment' }"
            class="sidenav__title"
          >
            Accompagnement
          </RouterLink>
        </template>
        <div class="row -mobile -align-center -spread sidenav__row" style="width: 100%">
          <RouterLink v-scroll:reveal="{ delay: 275, offset: 0 }" :to="{ name: 'themes' }" class="sidenav__title">
            Boîte à outils
          </RouterLink>

          <RouterLink
              v-scroll:reveal="{ delay: 375, offset: 0 }"
              :to="{ name: 'themes' }"
              class="sidenav__button button -link -flat -corner-right"
          >Voir tout
          </RouterLink>
        </div>

        <h2 class="section__title sidenav__subtitle">Thématiques en vedette</h2>


        <menu class="sidenav__list">
          <RouterLink
            v-for="(theme, idx) in themes"
            :key="theme.id"
            v-scroll:reveal="{ delay: 325 + 25 * idx, offset: 0 }"
            :to="{ name: 'theme', params: { themeSlug: theme.slug } }"
            class="sidenav__item -leafy"
          >
            <Icon id="leaves-dark" class="sidenav__leaves" />
            {{ theme.title }}
          </RouterLink>
        </menu>

        <RouterLink v-scroll:reveal="{ delay: 375, offset: 0 }" :to="{ name: 'projects' }" class="sidenav__title">
          <Seal class="sidenav__seal" />
          Projets
        </RouterLink>

        <RouterLink
          v-scroll:reveal="{ delay: 325, offset: 0 }"
          :to="{ name: 'trainings' }"
          class="sidenav__title -training"
        >
          Formations & Événements
        </RouterLink>

        <RouterLink
            v-if="$device.mobile"
            v-scroll:reveal="{ delay: 325, offset: 0 }"
            :to="{ name: 'involve' }"
            class="sidenav__title "
        >
          S’impliquer
        </RouterLink>
      </nav>

      <header class="sidenav__header">
        <menu class="sidenav__menu" v-if="$device.tablet">
            <RouterLink v-scroll:reveal="{ delay: 175 }" class="sidenav__link" :to="{ name: 'program' }">
              <Icon id="leaf" class="sidenav__link-leaf" />
              À propos
            </RouterLink>

            <RouterLink v-scroll:reveal="{ delay: 225 }" class="sidenav__link" :to="{ name: 'accompaniment' }">
              <Icon id="leaf" class="sidenav__link-leaf" />
              Accompagnement
            </RouterLink>

            <span v-scroll:reveal="{ delay: 275 }">
              <RouterLink
                class="sidenav__link button -link -flat -secondary -alt -corner-right"
                :to="{ name: 'involve' }"
                >S'impliquer
              </RouterLink>
            </span>
          <!-- <GoogleSearchBar v-show="!$device.tablet" /> -->
        </menu>
        <img src="/images/logoDark.svg" width="91" height="42" class="sidenav__header-logo" alt="logo" v-scroll:reveal v-if=""/>

        <button v-scroll:reveal="{ delay: 325 }" class="burger -close" @click="$emit('close')">
          <div style="display: flex; align-items: flex-end">
            <div style="font-size: 1rem; margin-bottom: -0.15rem; margin-right: 0.25rem;">
              ✕
            </div>
            <div>
              <span class="burger__line" />
              <span class="burger__line -middle" />
            </div>
          </div>

          <span class="burger__line" />
        </button>
      </header>
    </div>
  </transition>
</template>

<script>
import { Seal } from '@/components/graphics'
import { GoogleSearchBar } from '@/components/googleServices'

export default {
  name: 'SideNav',
  components: { Seal, GoogleSearchBar },
  props: {
    isVisible: { type: Boolean, default: false }
  },
  computed: {
    themes() {
      return Object.values(this.$nova.themes).slice(0, 5).sort((a, b) => b.title.length - a.title.length);

    }
  },
  watch: {
    isVisible(isVisible) {
      if (isVisible) this.$scroll.prevent()
      else this.$scroll.restore()
    },
    '$route.path'() {
      this.$emit('close')
    }
  }
}
</script>
