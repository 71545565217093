<template>
  <li class="card -theme" :class="{'-upcoming': theme.upcoming}">
    <RouterLink
      :to="{ name: 'theme', params: { themeSlug: theme.slug } }"
      :aria-label="getAriaLabel(theme.title)"
      class="row">

      <div v-if="!theme.upcoming" class="card__medias">
        <figure class="card__medias__image -normal" :style="`background-image: url('${theme.image}')`" />
      </div>

      <div class="card__content">
        <div class="row -spread">

          <div class="row__item -span-10">
            <h2 class="card__title">{{ theme.title }}</h2>
            <p>{{ theme.excerpt }}</p>
          </div>

          <div v-if="theme.upcoming" class="card__upcoming">
            À venir
          </div>

          <div class="row__item -span-2 card__explore" v-else>
            <div class="card__medias__link link -after">
              {{ labels.explore }}
              <Icon id="leaves" />
            </div>

            <div class="nbsp" v-if="getTotalToolsCount">
              <Icon id="theme-tool" />
              {{ getTotalToolsCount }}
            </div>
          </div>
        </div>

        <div class="card__list" v-if="getChildrens.length">
          <RouterLink
              v-for="child in getChildrens"
              :to="{ name: 'theme', params: { themeSlug: theme.slug, childThemeSlug: child.slug } }"
              :key="child.id" class="card__list-item">
            <span>{{ child.title }}</span>
            <Icon id="leaves"/>
          </RouterLink>
        </div>
      </div>
    </RouterLink>
  </li>
</template>

<script>
export default {
  name: 'CardTheme',
  props: {
    itemId: { type: Number, default: null },
    itemClass: { type: [String, Array, Object], default: Array },
    isTheme: { type: Boolean, default: null },
    labels: { type: Object, required: true }
  },
  computed: {
    theme() {
      return this.$nova.themes[this.itemId]
    },
    getTotalToolsCount() {
      const theme = this.theme
      if (theme.children.length) {
        const toolChildrenCount = theme.children.reduce((toolCount, child) => {
          return child.tools.length + toolCount
        }, 0)
        const total = toolChildrenCount + theme.tools.length
        return total > 0 ? `${total} outil${this.pluralize(total)}` : null
      }
      return theme.tools.length > 0 ? `${theme.tools.length} outil${this.pluralize(theme.tools.length)}` : 0
    },
    getChildrens() {
      return this.theme.children || []
    },
  },
  methods: {
    getAriaLabel(themeTitle) {
      return `Explorer ${themeTitle}`
    },
    pluralize(count) {
      return count > 1 ? 's' : ''
    },


  }
}
</script>
