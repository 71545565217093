<template>
  <div v-if="!$page" />

  <div v-else class="involve">
    <header class="header -column">
      <h1 v-scroll:reveal="{ delay: 550 }" class="header__subtitle">{{ $page.header.hat }}</h1>
      <p v-scroll:reveal="{ delay: 600 }" class="header__title">{{ $page.header.title }}</p>
    </header>

    <section v-scroll:reveal="{ delay: 650 }" class="section -first -flower">
      <div class="involve__header">
        <div class="row__item -span-7">
          <h2 v-scroll:reveal="{ delay: 700 }" class="section__title">{{ $page.partners.title }}</h2>

          <WrapHtml>{{ $page.partners.description }}</WrapHtml>

          <div id="qui">
            <h2 class="section__subtitle">{{ $page.partners.who.title }}</h2>
            <WrapHtml>{{ $page.partners.who.description }}</WrapHtml>
          </div>
        </div>

        <img src="/images/home-program.svg" class="involve__header-image" alt="" />

      </div>

      <div id="pourquoi">
        <h3 class="section__subtitle">{{ $page.partners.why.title }}</h3>
        <div class="row">
          <div class="row__item -span-6">
            <WrapHtml>{{ $page.partners.why.description.left }}</WrapHtml>

          </div>
          <div class="row__item -span-5 -skip-1">
            <WrapHtml>{{ $page.partners.why.description.right }}</WrapHtml>

          </div>
        </div>
      </div>


      <div>
        <Blob shape="b" squish light bottom/>
        <h3 class="section__subtitle">{{ $page.partners.members.title }}</h3>
        <div class="row -spread">
          <div v-for="(memberType, memberTypeKey) in $page.partners.members.items" :key="memberTypeKey" class="involve__members__item">
            <img :src="memberType.image" alt="" class="icon">
            <h4 class="involve__members__item-title">{{memberType.name}}</h4>
            <WrapHtml >{{memberType.description}}</WrapHtml>
            <RouterLink :to="memberType.link" class="section__link involve__members__item-link">
              <span>{{memberType.label}}</span>
              <Icon id="leaves" class="leaves"/>
            </RouterLink>
          </div>
        </div>
      </div>
    </section>

    <section id="ambassadeur" class="section row -align-center">
      <img :src="$page.ambassador.image" alt="site" style="width: 450px; height: auto" width="450" height="350"/>
      <div style="padding-left: 3rem;">
        <h2 v-scroll:reveal="{ delay: 100 }" class="section__title">{{ $page.ambassador.title }}</h2>
        <WrapHtml v-scroll:reveal="{ delay: 100 }">{{ $page.ambassador.description }}</WrapHtml>
      </div>
    </section>

    <CtaContact
      v-scroll:reveal="{ delay: 100 }"
      :title="$page.contact.title"
      :subtitle="$page.contact.description"
      :link-label="$page.contact.label"
      route-name="program"
    />
  </div>
</template>

<script>
import { PageMetaMixin } from '@/mixins'
import { CtaContact } from '@/components/partials'
import { Blob } from '@/components/graphics'

export default {
  name: 'Involve',
  novaPage: 'involved',
  components: {
    CtaContact,
    Blob
  },
  mixins: [PageMetaMixin]
}
</script>
