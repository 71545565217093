<template>
  <li class="card -training" :class="{'-light' : training.ended}">
    <ScopedLink :to="getTo()" class="row" :aria-label="`Plus d'infos ${training.title})`">
      <div v-if="!training.ended" class="card__medias">
        <figure class="card__medias__image -normal" :style="`background-image: url('${training.image}')`" />
      </div>
      <div class="card__content">


        <div class="row -spread">
          <h2 class="card__title"> <span v-if="training.ended" class="card__tag">{{ labels.ended }}</span>{{ training.title }}</h2>
          <div class="card__medias__link link -after" :style="{position: theme ? 'absolute' : 'initial'}">
            <span>{{ labels.info }}</span>
            <Icon id="leaves" />
          </div>
        </div>

        <p v-if="sponsors.length" class="card__creator">
          <span>{{ `${labels.offered_by} ` }}</span>
          <span v-for="(sponsor, i) in sponsors" :key="sponsor.id">
            {{ sponsor.name }}<span v-if="i !== sponsors.length - 1">, </span>
          </span>
        </p>

        <p v-if="!theme">{{ training.excerpt }}</p>

        <div class="card__date">
          <p class="card__status">
            <Icon id="internal" />
            {{ categoryLabel }}
          </p>

          <p class="card__status -calendar" v-if="!theme">
            <Icon id="calendar"/>
            {{ training.date }}
          </p>
        </div>

      </div>
    </ScopedLink>
  </li>
</template>

<script>
import { distributionModesDict } from '@/constants'
import { Icon } from '@/components/global'

export default {
  name: 'CardTraining',
  components: { Icon },
  props: {
    itemId: { type: Number, default: null },
    itemClass: { type: [String, Array, Object], default: Array },
    labels: {
      type: Object,
      required: true
    },
    theme: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    categoryLabel() {
      const slug = this.training.category || ''
      return distributionModesDict[slug]
    },
    sponsors() {
      const { sponsors = [] } = this.training || {}
      return sponsors.map(sponsorId => this.$nova.sponsors[sponsorId])
    },
    training() {
      return this.$nova.trainings[this.itemId]
    },
  },
  methods: {
    getTo() {
      return this.training.isExternal
        ? this.training.url
        : { name: 'training', params: { trainingSlug: this.training.slug } }
    }
  }
}
</script>
