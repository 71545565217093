<template>
  <div class="search-container"><div class="gcse-search"></div></div>
</template>

<script>
export default {
  name: 'GoogleSearchBar',
  data() {
    return {
      searchEngineApiKey: process.env.VUE_APP_GOOGLE_SEARCH_ENGINE_KEY,
      searchScript: null
    }
  },
  mounted() {
    this.$nextTick(this.loadSearchEngine)
  },
  beforeDestroy() {
    this.removeScript()
  },
  methods: {
    loadSearchEngine() {
      this.searchScript = document.createElement('script')
      this.searchScript.src = `https://cse.google.com/cse.js?cx=${this.searchEngineApiKey}`
      this.searchScript.addEventListener('load', () => {
        this.isSearchApiLoaded = true
      })
      document.head.appendChild(this.searchScript)
    },
    removeScript() {
      this.searchScript.remove()
      Object.values(document.getElementsByTagName('script')).forEach(script => {
        if (
          script.src === 'http://cse.google.com/adsense/search/async-ads.js' ||
          script.src === 'https://www.google.com/cse/static/element/ff97a008b4153450/cse_element__fr.js?usqp=CAI%3D'
        ) {
          script.remove()
        }
      })
    }
  }
}
</script>
