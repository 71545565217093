<template>
  <RouterLink :to="to" class="section__link">
    <div>
      {{ formattedLabel.start }}
      <span class="section__nowrap">{{ formattedLabel.last }}</span>
    </div>
    <Icon id="leaves" />
  </RouterLink>
</template>

<script>
export default {
  name: 'IconRouterLink',
  props: {
    label: { type: String, default: null },
    to: { type: Object, default: () => ({ name: 'home' }) }
  },
  computed: {
    formattedLabel() {
      const words = this.label.split(' ')
      const [last] = words.splice(-1, 1)
      return { start: words.join(' '), last }
    }
  }
}
</script>
