import pace from 'pace-progress'
import axios from 'axios'

pace.start()

pace.once('hide', () => {
  window.prerenderReady = true
})

axios('/icons.svg').then(({ data }) => document.body.insertAdjacentHTML('afterbegin', data))
