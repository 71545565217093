export default {
  metaInfo() {
    const { seo = {} } = this.$page || {}
    return {
      title: seo.title,
      meta: [
        { name: 'description', content: seo.description },
        {
          name: 'keywords',
          content: 'Ville, éponge, nature, verdissement, citoyen, engagement, partenaires, formations, outils, projets'
        },
        { property: 'og:title', content: `${seo.title} | Ville éponge` },
        { property: 'og:description', content: seo.description },
        { property: 'og:image', content: seo.image },
        { property: 'og:url', content: 'https://eponge.org' }
      ]
    }
  }
}
