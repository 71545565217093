<template>
  <div v-if="!$page || !project" />
  <div v-else class="project page">

    <header class="header header-project">
      <div class="header-project__content">
        <RouterLink v-scroll:reveal="{ delay: 550 }" :to="{ name: 'projects' }" class="link -back">
          <Icon id="leaves" class="back" />
          <span>{{ $page.labels.return }}</span>
        </RouterLink>

        <h1 v-scroll:reveal="{ delay: 600 }" class="header__title">{{ project.title }}</h1>

        <div v-scroll:reveal="{ delay: 650 }" class="project__status">
          <p class="project__status-item">
            <Icon :id="project.status" class="project__status-icon -sm" />
            {{ getStatus(project.status) }}
          </p>

          <p class="project__status-item">
            <Icon :id="`project-${project.category}`" class="project__status-icon" />
            {{ getCategory(project.category) }}
          </p>

          <p v-if="project.region" class="project__status-item">
            <Icon id="project-location" class="project__status-icon" />
            {{ project.region }}
          </p>
        </div>
      </div>
    </header>

    <SwiperProject :is-eponge="project.isEponge" :slides="project.medias"/>

    <section v-scroll:reveal="{ delay: 750, offset: 0 }" class="section -first">

      <div class="project__content">
        <div class="project__content-text">
          <h2 class="section__title -flower">{{ $page.labels.context }}</h2>

          <RawHtml v-scroll:reveal :html="project.context" />
          <h2 v-scroll:reveal class="section__title -project">{{ $page.labels.conclusions }}</h2>
          <RawHtml v-scroll:reveal :html="project.conclusions" />
          <h3 class="section__subtitle">{{ project.sectionFilesTitle }}</h3>
          <div class="row -wrap">
            <a
              v-for="link in project.links"
              :key="link.key"
              :href="link.attributes.url"
              class="cta-tool -project"
              target="_blank"
              rel="noopener"
              ><span class="cta-tool__icon bubble" :style="{backgroundColor: '#007358'}"><Icon :id="getToolIcon(link.attributes)" /></span>
              <p class="cta-tool__label -project">{{ link.attributes.linkLabel }}</p></a>

            <a
              v-for="file in project.files"
              :key="file.key"
              :href="file.url"
              class="cta-tool -project"
              target="_blank"
              rel="noopener"
              ><span class="cta-tool__icon bubble -tool -project" :style="getToolColor(file, true)"><Icon :id="getToolIcon(file, true)" /></span>
              <p class="cta-tool__label -project">{{ file.name }}</p></a>
          </div>
        </div>

        <div class="project__content-aside">
          <template v-for="(key, index) in firstFields">
            <div v-if="!key || project[key]" :key="key" :class="['project__field', { '-border': !key }]">
              <template v-if="key">
                <p v-scroll:reveal="{ delay: index * 75 }" class="project__field-key">{{ $page.labels[key] }}</p>
                <p v-scroll:reveal="{ delay: index * 75 + 75 }" class="project__field-value">{{ `${project[key]}` }}</p>
              </template>
            </div>
          </template>

          <div class="project__separator" v-scroll:reveal/>

          <div class="project__status-more">
            <Button class="button -alt -corner-left theme__tools__more" @click="infoOpen = !infoOpen">
              <span class="theme__tools__more-icon"> {{ infoOpen ? '-' : '+' }} </span>
              de détails
            </Button>
          </div>

          <DropDown :is-open="infoOpen">
            <template v-for="(key, index) in fields">
              <div v-if="!key || project[key]" :key="key" :class="['project__field', { '-border': !key }]">
                <template v-if="key">
                  <p v-scroll:reveal="{ delay: index * 75 }" class="project__field-key">{{ $page.labels[key] }}</p>
                  <p v-scroll:reveal="{ delay: index * 75 + 75 }" class="project__field-value">{{ `${project[key]}` }}</p>
                </template>
              </div>
            </template>

            <div class="project__separator" v-scroll:reveal/>


            <div v-for="(value, name, i) in sponsorTypesDict" :key="i" class="project__partners__item">
              <p v-scroll:reveal class="project__field-key">{{ $page.labels[name] }}</p>
              <div class="project__partners__item-logo-list">
                <a
                    v-for="sponsor in getSponsorsByCategory(name)"
                    :key="sponsor.id"
                    :href="sponsor.url"
                    class="project__partners__item-logo-link"
                    target="_blank"
                    rel="noopener"
                    :aria-label="sponsor.name"
                >
                  <figure
                      v-scroll:reveal
                      class="project__partners__background"
                      :style="getBackgroundImage(sponsor.image)"
                  />
                </a>
              </div>
            </div>
          </DropDown>
        </div>
      </div>
    </section>

    <section v-if="project.honors" class="section -mid-top">
      <h2 class="section__title" v-scroll:reveal>{{ $page.labels.honors }}</h2>
      <WrapHtml :html="project.honors" v-scroll:reveal/>

      <div v-for="(honor, key) in project.honors" :key="`honors${key}`" class="row -align-center -mobile project__honor" v-scroll:reveal>
        <img :src="honor.image" class="row__item -span-1 project__honor-image" width="82" height="82">
        <p class="row__item project__honor-text" v-html="honor.title"/>
      </div>
    </section>

    <CtaContact
      v-scroll:reveal
      :title="contactTitle"
      :subtitle="contactDescription"
      :link-label="contactLink"
      :to="contactTo"
    />
  </div>
</template>

<script>
import { PageMetaMixin } from '@/mixins'
import { CtaContact, SliderProject } from '@/components/partials'
import { categoryNames, sponsorTypesDict, statusNames, supportedExtension } from '@/constants'
import DropDown from "@/components/global/DropDown.vue";
import SwiperProject from "@/components/partials/SwiperProject.vue";

export default {
  name: 'Project',
  components: {SwiperProject, DropDown, CtaContact, SliderProject },
  novaPage: 'project',
  mixins: [PageMetaMixin],
  props: {
    projectSlug: { type: String, default: null }
  },
  metaInfo() {
    if (this.project) {
      const { seo = {} } = this.$page || {}
      const title = this.project.seoTitle || this.project.title
      const description = this.project.seoDescription || seo.description
      const image = this.project.seoImage || this.project.medias[0].image

      return {
        title: `${title} | Projets`,
        meta: [
          { name: 'description', content: description },
          {
            property: 'og:title',
            content: `${title} | Projets | Ville éponge`
          },
          { property: 'og:description', content: description },
          { property: 'og:image', content: image }
        ]
      }
    }
  },
  data() {
    return {
      fields: [
        'timeframe',
        'projectBudget',
        'maintenanceBudget',
        'fundingBudget',
        'savings'
      ],
      firstFields: [
        'address',
        'infrastructures',
        'area',
      ],
      infoOpen: !this.$device.phone
    }
  },
  computed: {
    contactDescription() {
      return this.project.cta ? this.project.cta.description : this.$page.contact.description
    },
    contactTitle() {
      return this.project.cta ? this.project.cta.title : this.$page.contact.title
    },
    contactLink() {
      return this.project.cta ? this.project.cta.label ? this.project.cta.label : this.$page.contact.label : this.$page.contact.label
    },
    contactTo() {
      return this.project.cta ? this.project.cta.link ? this.project.cta.link : this.$page.contact.link : this.$page.contact.link
    },
    project() {
      return Object.values(this.$nova.projects).find(({ slug }) => slug.includes(this.projectSlug))
    },
    sponsorTypesDict() {
      return sponsorTypesDict
    }
  },
  beforeMount() {
    if (!this.project) this.$router.replace({ name: 'not-found' })
  },
  methods: {
    getSponsorsByCategory(category) {
      const { sponsors = {} } = this.project || {}
      return Object.entries(sponsors).reduce((sponsorMap, [sponsorId, sponsorType]) => {
        return sponsorType.includes(category)
          ? { ...sponsorMap, [sponsorId]: this.$nova.sponsors[sponsorId] }
          : { ...sponsorMap }
      }, {})
    },
    getToolIcon(item, isFile) {
      if (item.isFile || isFile && item.url) {
        const extension = item.url.split('.').pop()
        return supportedExtension.indexOf(extension) !== -1 ? `tool-${extension}` : 'tool-doc'
      }
      return 'tool-link'
    },
    getToolColor(item, isFile){
      const extension = item.url.split('.').pop()
      if(extension === 'pdf') return '#0BBBA4'
      else return '#FF6E5F'
    },
    getBackgroundImage(url) {
      return `background-image: url('${url}')`
    },
    getStatus(slug) {
      return statusNames[slug]
    },
    getCategory(slug) {
      return categoryNames[slug]
    },
    getHref(item) {
      return item.isExternal ? item.url : item.file
    }
  }
}
</script>
