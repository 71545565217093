const defaults = {
  ratio: 0.25,
  offset: 0
}

export default ScrollHub => ({
  add({ uid, node, value = {} }) {
    const {
      refNode = node.offsetParent,
      ratio: inputRatio = defaults.ratio,
      offset = defaults.offset,
      xPos = 0
    } = value

    const prefix = `translate3d(${xPos}px,`
    const suffix = 'px,0)'
    const ratio = inputRatio * -1
    node.dataset.scrollId = uid

    const getTranslateOffset = () => (refNode.getBoundingClientRect().top - offset) * ratio

    ScrollHub.bind(uid, () => {
      node.style.transform = prefix + getTranslateOffset() + suffix
    })
  },
  remove(uid) {
    ScrollHub.unbind(uid)
  },
  setOptions({ parallax }) {
    if (!parallax) return
    if (parallax.ratio) defaults.ratio = parallax.ratio
    if (parallax.offset) defaults.offset = parallax.offset
  }
})
