<template>
  <transition appear name="contact-panel">
    <section v-if="$route.query.contact" class="contact-panel">
      <transition name="fade" mode="out-in">
        <form v-if="isFormVisible" class="contact-panel__form" @submit.prevent="onSubmit">
          <h2 v-scroll:reveal="{ delay: 250 }" class="section__title">{{ contact.title }}</h2>
          <RawHtml v-scroll:reveal="{ delay: 300 }" class="contact-panel__instructions" :html="contact.legend">
          </RawHtml>

          <InputText
            v-model="model.name"
            v-scroll:reveal="{ delay: 350 }"
            name="name"
            :label="contact.name.label"
            :error="error.isNameInvalid"
            :error-message="errorMessage.name"
            :has-focus="true"
            @validate="validateName"
          />

          <InputText
            v-model="model.email"
            v-scroll:reveal="{ delay: 400 }"
            name="courriel"
            :label="contact.email.label"
            :error="error.isEmailInvalid"
            :error-message="errorMessage.email"
            @validate="validateEmail"
          />

          <InputText
            v-model="model.message"
            v-scroll:reveal="{ delay: 450 }"
            name="message"
            :label="contact.message.label"
            textarea
            :error="error.isMessageInvalid"
            :error-message="errorMessage.message"
            @validate="validateMessage"
          />
          <button
            v-scroll:reveal="{ delay: 450, offset: 0 }"
            class="button -secondary -corner-top-left contact-panel__button"
            type="submit"
            @click="validateForm"
          >
            {{ contact.buttonLabel }}
          </button>
        </form>

        <div v-else-if="isConfirmCardVisible">
          <h3 v-scroll:reveal="{ delay: 250 }" class="section__title">{{ contact.confirmation.title }}</h3>
          <p v-scroll:reveal="{ delay: 300 }" class="section__subtitle">{{ contact.confirmation.legend }}</p>
        </div>
      </transition>

      <RouterLink
        v-scroll:reveal="{ delay: 300, offset: 0 }"
        :to="closePanelLink"
        class="contact-panel__close bubble -close"
        aria-label="Fermer le formulaire de contact"
      >
        <Icon id="closeContact" class="close" />
      </RouterLink>
    </section>
  </transition>
</template>

<script>
import axios from 'axios'
import { InputText } from './inputs'

export default {
  name: 'FormContact',
  components: { InputText },
  props: {
    isOpen: { type: Boolean, default: false }
  },
  data() {
    return {
      model: {
        name: '',
        email: '',
        message: ''
      },
      isFormVisible: true,
      isConfirmCardVisible: false,
      error: {
        isNameInvalid: false,
        isEmailInvalid: false,
        isMessageInvalid: false
      },
      errorMessage: {
        name: '',
        email: '',
        message: ''
      },
      regex: {
        name: /^[a-zA-ZÀ-ÿ\- ]{1,50} [a-zA-ZÀ-ÿ\- ]{1,50}$/,
        email:
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        message: /^[0-9a-zA-ZÀ-ÿ\- ]{1,500}$/
      }
    }
  },
  computed: {
    closePanelLink() {
      const { contact, ...query } = this.$route.query
      return { name: this.$route.name, query }
    },
    contact() {
      return this.$nova.options.contact
    }
  },
  watch: {
    model: {
      deep: true,
      handler() {
        this.showRecaptcha()
      }
    }
  },
  methods: {
    showRecaptcha() {
      document.getElementsByClassName('grecaptcha-badge')[0].style.visibility = 'visible'
    },
    getMailTo(url) {
      return `mailto:${url}`
    },
    resetForm() {
      this.model.name = ''
      this.model.email = ''
      this.model.message = ''
    },
    validateName() {
      if (!this.model.name) {
        this.error.isNameInvalid = true
        this.errorMessage.name = this.contact.name.errorEmpty
      } else if (!this.regex.name.test(this.model.name)) {
        this.error.isNameInvalid = true
        this.errorMessage.name = this.contact.name.errorPattern
      } else {
        this.error.isNameInvalid = false
      }
    },
    validateEmail() {
      if (!this.model.email) {
        this.error.isEmailInvalid = true
        this.errorMessage.email = this.contact.email.errorEmpty
      } else if (!this.regex.email.test(this.model.email.toLowerCase())) {
        this.error.isEmailInvalid = true
        this.errorMessage.email = this.contact.email.errorPattern
      } else {
        this.error.isEmailInvalid = false
      }
    },
    validateMessage() {
      if (!this.model.message) {
        this.error.isMessageInvalid = true
        this.errorMessage.message = this.contact.message.errorEmpty
      } else if (!this.regex.message.test(this.model.message)) {
        this.error.isEmailInvalid = true
        this.errorMessage.message = this.contact.message.errorPattern
      } else {
        this.error.isMessageInvalid = false
      }
    },
    validateForm() {
      let isFormValid = true
      if (this.error.isNameInvalid) {
        isFormValid = false
      }
      if (this.error.isEmailInvalid) {
        isFormValid = false
      }
      if (this.error.isMessageInvalid) {
        isFormValid = false
      }
      return isFormValid
    },
    onSubmit() {
      this.validateName()
      this.validateEmail()
      this.validateMessage()

      const isFormValid = this.validateForm()

      if (isFormValid) {
        const { name, email, message } = this.model
        this.showConfirmCard()
        this.resetForm()
        this.$recaptcha('contact').then(() => {
          axios({
            method: 'post',
            baseURL: process.env.VUE_APP_API_URL,
            url: '/contact',
            data: { name, email, message }
          })
        })
      }
    },
    showConfirmCard() {
      this.isFormVisible = false
      this.isConfirmCardVisible = true
    },
    hideConfirmCard() {
      this.isConfirmCardVisible = false
      this.isFormVisible = true
    }
  }
}
</script>
