<template>
  <div v-if="!$page" />

  <div v-else class="program page">
    <header class="header -column">
      <h1 v-scroll:reveal="{ delay: 550 }" class="header__subtitle">{{ $page.header.hat }}</h1>
      <HighlightedTitle v-scroll:reveal="{ delay: 600 }" :title="$page.header.title" class="header__title -about" />
    </header>


    <section class="section -mid-top row program__challenges">
      <div v-scroll:reveal="{ delay: 700 }" class="section__content -flower -pad-right row__item -span-7 ">
        <h2 class="section__title">{{ $page.header.subtitle }}</h2>
        <RawHtml :html="$page.header.description" />
        <a :href="$page.header.link" target="_blank" class="section__link">
          <span>{{ $page.header.label }}</span>
          <Icon id="leaves" class="button__icon" />
        </a>
      </div>

      <figure
        v-scroll:reveal="{ delay: 650 }"
        class="program__challenges-background"
        :style="{ backgroundImage: 'url(/images/program-header.svg)' }"
      />
    </section>

    <section id="mission" class="section -centered">
      <Blob shape="c" light/>
      <div v-scroll:reveal class="program__solutions__block -wave">
        <h3 class="section__subtitle">{{ $page.mission.hat }}</h3>
        <HighlightedTitle v-scroll:reveal="{ delay: 600 }" :title="$page.mission.title" class="section__title" />
      </div>
      <Video :video="$page.mission.video" v-if="$page.mission.video"/>
      <Video mobile :video="$page.mission.video" v-if="$page.mission.video"/>
    </section>

    <section id="solutions" class="section program__solutions">
      <div class="row -wrap">
        <div
          v-for="(solution, solutionKey) in $page.solutions.items"
          :key="solutionKey"
          v-scroll:reveal
          class="program__solutions__block"
        >
          <h3 class="section__title">{{ solution.title }}</h3>
          <RawHtml :html="solution.description" />
        </div>
      </div>
    </section>

    <section class="section -mid-top">
      <div id="vision" v-scroll:reveal class="program__solutions__block -wave -vision">
        <h3 class="section__subtitle">{{ $page.vision.hat }}</h3>
        <HighlightedTitle v-scroll:reveal="{ delay: 600 }" :title="$page.vision.title" class="section__title" />
        <a :href="$page.document.file" target="_blank" rel="noopener" class="cta-tool -program" v-if="$page.document.file">
          <span class="cta-tool__icon">
            <Icon id="tool-pdf" />
          </span>

          <span class="cta-tool__label">{{ $page.document.title }}</span>
        </a>
      </div>
    </section>

    <section id="objectifs" class="section program__services">
      <h2 v-scroll:reveal class="section__title">{{ $page.goals.title }}</h2>

      <div class="row -spread program__services__list">
        <article
          v-for="(goal, goalKey) in $page.goals.items"
          :key="`objective-${goalKey}`"
          v-scroll:reveal="{ delay: 50 * goalKey }"
          class="program__services__item"
        >
          <figure class="program__services__item__background" :style="getBackgroundImage(goal.image)" />
          <p>{{ goal.name }}</p>
        </article>
      </div>
    </section>


    <section id="actions" class="section program__actions">
      <blob shape="b" light/>
      <h2 v-scroll:reveal class="section__title">{{ $page.actions.title }}</h2>

      <ul v-scroll:reveal="{ delay: 100 }" class="row -mobile program__actions__list">
        <li
          v-for="(item, idx) in $page.actions.items"
          :key="`action-${idx}`"
          v-scroll:reveal="{ delay: idx * 75 }"
          class="program__actions__item"
        >
          <figure class="program__actions__item__background" :style="getBackgroundImage(item.image)" />
          <div class="program__actions__item__content">
            <h3 class="program__actions__item__title">{{ item.name }}</h3>
            <WrapHtml>{{ item.description }}</WrapHtml>
            <RouterLink :to="item.link" class="section__link">
              <span>{{ item.label }}</span>
              <Icon id="leaves" />
            </RouterLink>
          </div>
        </li>
      </ul>
    </section>

    <section id="partenaires" class="section program__partners">
      <div class="row -spread">
        <div class="row__item -span-9">
          <h2 v-scroll:reveal class="section__title">{{ $page.partners.title }}</h2>
          <p class="program__partners__text">{{ $page.partners.description }}</p>
          <RouterLink v-scroll:reveal :to="{ name: 'involve' }" class="section__link">
            {{ $page.partners.label }}
            <Icon id="leaves" />
          </RouterLink>
        </div>
        <figure class="program__partners-badge" :style="getBackgroundImage(badgePartner)" />
      </div>

      <div v-for="(partnersGroup, partnersGroupKey) in $page.partners.items" :key="partnersGroupKey" class="program__partners__section">
        <h2 v-if="partnersGroup.important" v-scroll:reveal class="section__title">{{ partnersGroup.title }}</h2>
        <h3 v-if="!partnersGroup.important" v-scroll:reveal class="section__subtitle">{{ partnersGroup.title }}</h3>
        <ul class="program__partners__involve-list">
          <li
            v-for="(item, idx) in getSortedSponsor(partnersGroup.items)"
            :key="`partner${idx}`"
            v-scroll:reveal="{ delay: 25 * idx }"
            class="program__partners__involve-item"
          >
            <a :href="item.url" :aria-label="item.name" target="_blank" rel="noopener">
              <figure class="program__partners__background" :style="getBackgroundImage(item.image)" />
            </a>
          </li>
        </ul>
      </div>
    </section>

    <CtaContact
      v-scroll:reveal="{ delay: 100 }"
      :title="$page.contact.title"
      :subtitle="$page.contact.description"
      :link-label="$page.contact.label"
    />
  </div>
</template>

<script>
import { PageMetaMixin } from '@/mixins'
import { Blob } from '@/components/graphics'
import { CtaContact, HighlightedTitle } from '@/components/partials'
import Video from "@/components/partials/Video.vue";

export default {
  name: 'About',
  novaPage: 'about',
  components: {
    Video,
    CtaContact,
    HighlightedTitle,
    Blob
  },
  mixins: [PageMetaMixin],
  computed: {
    badgePartner() {
      return this.$nova.options.general.partner[0] || []
    }
  },
  methods: {
    getBackgroundImage(url) {
      return `background-image: url('${url}')`
    },
    getSortedSponsor(ids) {
      return ids
        .map(id => (this.$nova.sponsors[id] ? this.$nova.sponsors[id] : null))
        .filter(sponsor => sponsor !== null)
    }
  }
}
</script>
