<template>
  <footer class="footer">
    <p>
      © {{ currentYear }} | Une initiative du
      <a href="https://www.cre-capitale.org/" target="_blank" rel="noopener">
        Conseil régional de l’environnement - région de la Capitale-Nationale
      </a>
    </p>

    <p>
      Site conçu et développé par
      <a href="https://boitebeet.com" target="_blank" rel="noopener" class="footer__credit">Beet</a>
    </p>
  </footer>
</template>

<script>
export default {
  name: 'SiteFooter',
  data() {
    return {
      currentYear: new Date().getFullYear()
    }
  }
}
</script>
