const isObject = v => v !== null && v !== undefined && !Array.isArray(v) && typeof v === 'object'

export const keysToCamelCase = input =>
  !isObject(input)
    ? input
    : Object.keys(input).reduce((output, key) => {
        const value = input[key]
        const outputKey = key.replace(/_+(.)?/g, ($m, $1 = '') => $1.toUpperCase())

        return Array.isArray(value) && value.some(isObject)
          ? { ...output, [outputKey]: value.map(keysToCamelCase) }
          : { ...output, [outputKey]: isObject(value) ? keysToCamelCase(value) : value }
      }, {})
