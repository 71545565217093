<template>
  <Element :tag="tag">
    <template v-for="(str, index) in titleSplit">
      <template v-if="index % 2 === 0">{{ str }}</template>
      <Element v-else :key="str" :tag="highlightTag">{{ str }}</Element>
    </template>
  </Element>
</template>

<script>
export default {
  name: 'HighlightedTitle',
  props: {
    title: { type: String, default: '' },
    tag: { type: String, default: 'h2' },
    highlightTag: { type: String, default: 'em' }
  },
  computed: {
    titleSplit() {
      return this.title.split('*')
    }
  }
}
</script>
