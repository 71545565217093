<template>
  <a v-if="isExternal" :href="to" rel="noopener" target="_blank">
    <slot />
  </a>
  <RouterLink v-else :to="to" :aria-label="arialabel">
    <slot />
  </RouterLink>
</template>

<script>
export default {
  name: 'ScopedLink',
  props: {
    to: { type: [Object, String], required: true },
    arialabel: { type: String, default: null }
  },
  computed: {
    isExternal() {
      return typeof this.to === 'string' && /^http/.test(this.to)
    }
  }
}
</script>
