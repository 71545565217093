import { SET_PAGE, SET_READY, SET_TYPES, SET_OPTIONS, ADD_LOADING, REMOVE_LOADING } from './constants'

export default {
  [SET_PAGE]: (state, [key, pageData]) => {
    state.pages = { ...state.pages, [key]: pageData }
  },
  [SET_READY]: (state, isReady) => {
    state.isDataReady = isReady
  },
  [SET_TYPES]: (state, customTypes) => {
    state.customTypes = customTypes
  },
  [SET_OPTIONS]: (state, options) => {
    state.options = options
  },
  [ADD_LOADING]: (state, key) => {
    state.loadingStates = [...state.loadingStates, key]
  },
  [REMOVE_LOADING]: (state, key) => {
    state.loadingStates = state.loadingStates.filter(loadingKey => key !== loadingKey)
  }
}
