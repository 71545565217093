<template>
  <div v-if="!$page"></div>
  <div class="project" v-else>
    <header class="header -training">
      <div class="">
        <RouterLink v-scroll:reveal="{ delay: 550 }" :to="{ name: 'trainings' }" class="link -back">
          <Icon id="leaves" class="back" />
          <span>{{ $page.labels.return }}</span>
        </RouterLink>

        <h1 v-scroll:reveal="{ delay: 600 }" class="header__title">{{ training.title }}</h1>

        <div v-scroll:reveal="{ delay: 650 }" class="project__status">
          <p class="project__status-item">
            <Icon id="internal" class="project__status-icon -sm" />
            {{ categoryLabel }}
          </p>

          <p v-if="training.clientType" class="project__status-item">
            <Icon id="project-public" class="project__status-icon" />
            {{ training.clientType }}
          </p>

          <p v-if="training.price" class="project__status-item">
            <Icon id="project-cost" class="project__status-icon" />
            {{ training.price }}
          </p>

          <p v-if="training.date" class="project__status-item">
            <Icon id="calendar" class="project__status-icon"/>
            {{ training.date }}
          </p>
        </div>
      </div>
    </header>

    <section v-if="training" v-scroll:reveal="{ delay: 750, offset: 0 }" class="section -first">
      <div class="project__content">
        <div class="row__item -span-9">
          <h2 class="section__title -flower">{{ $page.labels.description }}</h2>
          <RawHtml
            v-if="training.description"
            v-scroll:reveal="{ delay: 800, offset: 0 }"
            :html="training.description"
          />
          <!-- File? -->
        </div>

        <div class="row__item -span-3 project__aside">
          <p v-scroll:reveal="{ delay: 1000, offset: 0 }" class="project__field">
            <a
              v-if="training.url"
              :href="training.url"
              target="_blank"
              rel="noopener"
              class="button -link -corner-top-left"
            >
              {{ $page.labels.button }}
            </a>
          </p>

          <p v-if="sponsors.length" class="project__field -training-label">
            <span v-scroll:reveal="{ delay: 850, offset: 0 }" class="project__field-key">{{
              $page.labels.offeredBy
            }}</span>
          </p>
          <div class="sponsors-list">
            <figure
              v-for="sponsor in sponsors"
              :key="sponsor.image"
              v-scroll:reveal="{ delay: 850, offset: 0 }"
              class="sponsors-list__item"
              :style="{ backgroundImage: `url('${sponsor.image}')` }"
            ></figure>
          </div>
        </div>
      </div>
    </section>

    <CtaContact
        v-scroll:reveal
        :title="training.cta.title"
        :subtitle="training.cta.description"
        :link-label="training.cta.label"
        route-name="program"
    />
  </div>
</template>

<script>
import { PageMetaMixin } from '@/mixins'
import { CtaContact } from '@/components/partials'
import { distributionModesDict } from '@/constants'

export default {
  name: 'Training',
  novaPage: 'training',
  components: { CtaContact },
  mixins: [PageMetaMixin],
  props: {
    trainingSlug: { type: String, default: null }
  },
  metaInfo() {
    if (this.training) {
      const { seo = {} } = this.$page || {}
      const title = this.training.seoTitle || this.training.title
      const description = this.training.seoDescription || seo.description
      const image = this.training.seoImage || this.training.image

      return {
        title: `${title} | Formations`,
        meta: [
          { name: 'description', content: description },
          {
            property: 'og:title',
            content: `${title} | Formations | Ville éponge`
          },
          { property: 'og:description', content: description },
          { property: 'og:image', content: image }
        ]
      }
    }
  },
  computed: {
    categoryLabel() {
      const slug = this.training.category || ''
      return distributionModesDict[slug]
    },
    contactDescription() {
      return this.training.cta ? this.training.cta.description : this.$page.contact.description
    },
    contactTitle() {
      return this.training.cta ? this.training.cta.title : this.$page.contact.title
    },
    sponsors() {
      const { sponsors = [] } = this.training || {}
      return sponsors.map(sponsorId => this.$nova.sponsors[sponsorId])
    },
    training() {
      return this.$store.getters.trainingsBySlug[this.trainingSlug]
    }
  },
  beforeMount() {
    if (!this.training) this.$router.replace({ name: 'not-found' })
  }
}
</script>
