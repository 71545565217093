<template>
  <div v-if="!$page" />

  <div v-else class="themes page">
    <header class="header -column">
      <h1 v-scroll:reveal="{ delay: 550 }" class="header__subtitle">{{ $page.header.hat }}</h1>
      <p v-scroll:reveal="{ delay: 600 }" class="header__title">{{ $page.header.title }}</p>
      <WrapHtml v-scroll:reveal="{ delay: 650 }">{{ $page.header.description }}</WrapHtml>
    </header>

    <section class="section -first">
      <h2 class="section__title">{{ $page.labels.view }}</h2>

      <ul v-scroll:reveal="{ delay: 750, offset: 0 }" class="theme__list row -wrap -spread">
        <CardTheme
            v-for="(item, idx) in themes"
            :key="item.id"
            v-scroll:reveal="{ delay: idx * 75, offset: 0 }"
            :item-id="item.id"
            class="themes__item"
            :labels="$page.labels"
        />
      </ul>
    </section>
      <CtaContact
          v-scroll:reveal="{ delay: 75 }"
          :title="$page.contact.title"
          :subtitle="$page.contact.description"
          :link-label="$page.contact.label"
      />
  </div>
</template>

<script>
import { PageMetaMixin } from '@/mixins'
import { CardTheme, CtaContact } from '@/components/partials'

export default {
  name: 'Themes',
  novaPage: 'themes',
  components: { CtaContact, CardTheme },
  mixins: [PageMetaMixin],
  computed: {
    themes() {
      return Object.values(this.$nova.themes).sort((a, b) => {
        if (a.upcoming && !b.upcoming) return 1;
        if (!a.upcoming && b.upcoming) return -1;
        return 0;
      }).sort((a, b) => {
        if (a.children.length && !b.children.length) return -1;
        if (!a.children.length && b.children.length) return 1;
        return 0;
      })
    }
  }
}
</script>
