<template>
  <div v-if="!$page" />
  <div v-else class="page">
    <header class="header -column">
      <h1 v-scroll:reveal="{ delay: 550 }" class="header__subtitle">{{ $page.header.hat }}</h1>
      <HighlightedTitle v-scroll:reveal="{ delay: 600 }" :title="$page.header.title" class="header__title" />
      <WrapHtml v-scroll:reveal="{ delay: 650 }" style="max-width: 769px">{{ $page.header.description }}</WrapHtml>
    </header>

    <section v-scroll:reveal="{ delay: 700, offset: 0 }" class="section -mobile-bleed">
      <GoogleMapFilters :labels="$page.labels" />
      <GoogleMapViewport :projects="projects" :labels="$page.labels" />
    </section>

    <CtaContact
      v-scroll:reveal="{ delay: 100 }"
      :title="$page.contact.title"
      :subtitle="$page.contact.description"
      :link-label="$page.contact.label"
      route-name="involve"
    />
  </div>
</template>

<script>
import { PageMetaMixin } from '@/mixins'
import { CtaContact, HighlightedTitle } from '@/components/partials'
import { GoogleMapViewport, GoogleMapFilters } from '@/components/googleServices'
import { ownerValues } from '@/constants'

export default {
  name: 'Projects',
  components: { CtaContact, HighlightedTitle, GoogleMapViewport, GoogleMapFilters },
  novaPage: 'projects',
  mixins: [PageMetaMixin],
  computed: {
    projects() {
      const { owner, category, status } = this.$route.query
      if (!owner && !category && !status) return Object.values(this.$nova.projects)

      return Object.values(this.$nova.projects).filter(project => {
        const matchesCategory = !category || project.category === category
        const projectOwner = project.isEponge ? ownerValues.eponge : ownerValues.other
        const matchesOwner = !owner || projectOwner === owner
        const matchesStatus = !status || project.status === status
        return matchesCategory && matchesOwner && matchesStatus
      })
    }
  }
}
</script>
