<template>
  <nav class="row -align-start map-filters">
    <FilterRadio v-bind="category" />
    <FilterRadio v-bind="owner" :has-icon="true" />
    <FilterRadio v-bind="status" />
  </nav>
</template>

<script>
import FilterRadio from '@/components/inputs/FilterRadio.vue'
import { statusNames } from '@/constants'

export default {
  name: 'GoogleMapFilters',
  components: { FilterRadio },
  props: {
    labels: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      model: {
        category: '',
        projectOwner: ''
      },
      category: {
        param: 'category',
        defaultLabel: this.labels.allCategories,
        options: {
          residential: 'Résidentiel',
          commercial: 'Commercial',
          municipal: 'Municipal',
          institutional: 'Institutionnel',
          industrial: 'Industriel'
        }
      },
      owner: {
        param: 'owner',
        defaultLabel: this.labels.allTypes,
        options: {
          eponge: 'Projets Ville Éponge',
          other: 'Autres projets'
        }
      },
      status: {
        param: 'status',
        defaultLabel: this.labels.allStatus,
        options: statusNames
      }
    }
  }
}
</script>
