<template>
  <div v-if="!$page" />
  <div v-else class="page">
    <header class="header -column">
      <h1 v-scroll:reveal="{ delay: 550 }" class="header__subtitle">{{ $page.header.hat }}</h1>
      <p v-scroll:reveal="{ delay: 600 }" class="header__title">{{ $page.header.title }}</p>
      <WrapHtml v-scroll:reveal="{ delay: 650 }">{{ $page.header.description }}</WrapHtml>
    </header>

    <section class="section -first">
      <div class="training-tabs">
        <button class="training-tab" @click="tab = 'trainings'" :class="{'-active' : tab === 'trainings'}" :disabled="trainingsCount === 0">
          <span>{{ $page.labels.trainings }}</span>
          <span class="training-tab__count">{{ trainingsCount }}</span>
        </button>

        <button class="training-tab" @click="tab = 'events'" :class="{'-active' : tab === 'events'}" :disabled="eventsCount === 0">
          <span>{{ $page.labels.events }}</span>
          <span class="training-tab__count">{{ eventsCount }}</span>
        </button>
      </div>

      <ul v-scroll:reveal="{ delay: 700, offset: 0 }" class="row -wrap -training-list">
        <CardTraining
          v-for="(item, idx) in tab === 'trainings' ? trainings : events"
          :key="item.id"
          v-scroll:reveal="{ delay: idx * 75, offset: 0 }"
          :item-id="item.id"
          class="themes__item"
          :labels="{ ended: $page.labels.ended, info: $page.labels.info, offered_by: $page.labels.offeredBy }"
        />
      </ul>
    </section>

    <CtaContact
      v-scroll:reveal="{ delay: 100 }"
      :title="$page.contact.title"
      :subtitle="$page.contact.description"
      :link-label="$page.contact.label"
      route-name="themes"
    />
  </div>
</template>

<script>
import { PageMetaMixin } from '@/mixins'
import { CardTraining, CtaContact } from '@/components/partials'

export default {
  name: 'Trainings',
  components: { CtaContact, CardTraining },
  novaPage: 'trainings',
  mixins: [PageMetaMixin],
  data() {
    return {
      tab: 'trainings'
    }
  },
  computed: {
    eventsCount() {
      return Object.values(this.$nova.trainings).filter(({ type }) => type === 'event').length
    },
    trainingsCount() {
      return Object.values(this.$nova.trainings).filter(({ type }) => type === 'training').length
    },
    events() {
      return Object.values(this.$nova.trainings).filter(({ type }) => type === 'event')
          .sort((a, b) => new Date(a.endDate) - new Date(b.endDate))
          .sort((a, b) => {
            if (a.ended && !b.ended) return 1;
            if (!a.ended && b.ended) return -1;
            return 0;
          })
    },
    trainings() {
      return Object.values(this.$nova.trainings).filter(({ type }) => type !== 'event')
          .sort((a, b) => new Date(a.endDate) - new Date(b.endDate))
          .sort((a, b) => {
              if (a.ended && !b.ended) return 1;
              if (!a.ended && b.ended) return -1;
              return 0;
            })
    }
  }
}
</script>
