import './styles'
import './startup'

import Vue from 'vue'
import VueMeta from 'vue-meta'
import DeviceQueries from 'vue-device-queries'
import { sync } from 'vuex-router-sync'
import Recaptcha from './extensions/Recaptcha'

// import VueAnalytics from 'vue-analytics'

import router from './router'
import App from './App.vue'
import Nova from './extensions/Nova'
import ScrollUtils from './extensions/ScrollUtils'
import ScrollHub from './extensions/ScrollHub'
import GlobalComponents from './components/global'
import { store, reduceNovaData } from './store'
import { toRem } from './helpers'

Vue.config.productionTip = false

Vue.use(VueMeta)
Vue.use(Nova, store, reduceNovaData)
Vue.use(GlobalComponents)
Vue.use(Recaptcha)
Vue.use(ScrollUtils, { scrollEventTarget: window })
// Vue.use(VueAnalytics, { id: 'UA-178215821-1', router })

Vue.use(ScrollHub, {
  scrollingElement: document.scrollingElement || document.documentElement,
  scrollEventTarget: window,
  reveal: { offset: window.innerHeight * 0.075 }
})

Vue.use(DeviceQueries, {
  phone: `max-width: ${toRem(567)}`,
  tablet: `min-width: ${toRem(568)}`,
  mobile: `max-width: ${toRem(1024)}`,
  laptop: `min-width: ${toRem(1025)}`,
  desktop: `min-width: ${toRem(1360)}`
})

sync(store, router)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
