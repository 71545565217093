<template>
  <div class="slider">
    <div class="slider__content -mobile">
      <figure
        v-if="isEponge"
        class="slider__content__badge -mobile"
        :style="getBackgroundImage($nova.options.general.badge)"
      />
      <figure
        v-for="item in slides"
        :key="item"
        class="slider__content__item -mobile -image"
        :style="getBackgroundImage(item)"
      />
    </div>
    <div v-if="slides.length > 1" class="row">
      <div class="slider__panel">
        <span class="active">{{ String(1).padStart(2, 0) }}</span
        ><span class="total">{{ String(slides.length).padStart(2, 0) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SliderMobileProject',
  props: {
    isEponge: { type: Number, default: null },
    slides: { type: Array, default: Array },
    itemClass: { type: [String, Array, Object], default: Array }
  },
  methods: {
    getBackgroundImage(url) {
      return `background-image: url('${url}')`
    }
  }
}
</script>
