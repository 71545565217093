<template>
  <div class="slider">
    <div class="row slider__top">
      <h3 class="section__subtitle">{{ title }}</h3>
      <nav class="slider__controls">
        <button @click="onClickPrev"><Icon id="back" class="back" /></button>
        <button @click="onClickNext"><Icon id="next" /></button>
      </nav>
      <div class="slider__panel">
        <span class="active">{{ String(currentIndex + 1).padStart(2, 0) }}</span
        ><span class="total">{{ String(slides.length).padStart(2, 0) }}</span>
      </div>
    </div>
    <div class="slider__content">
      <p class="slider__content__item">{{ currentSlide }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SliderObj',
  props: {
    slides: { type: Array, default: Array },
    itemClass: { type: [String, Array, Object], default: Array },
    title: { type: String, default: null }
  },
  data() {
    return {
      currentIndex: 0
    }
  },
  computed: {
    currentSlide() {
      return this.slides[this.currentIndex].content
    }
  },
  methods: {
    onClickNext() {
      this.currentIndex = (this.currentIndex + 1) % this.slides.length
    },
    onClickPrev() {
      const index = this.currentIndex || this.slides.length
      this.currentIndex = index - 1
    }
  }
}
</script>
