<template>
  <svg xmlns="http://www.w3.org/2000/svg" :viewBox="svg.viewBox"
       :class="['blob', `-${shape}`, { '-squish': squish }, {'-light' : light}, {'-bottom': bottom}]">
    <path :d="svg.path" />
  </svg>
</template>

<script>
import { blobs } from './shapes.json'

export default {
  name: 'Blob',
  props: {
    shape: { type: String, default: 'a' },
    squish: { type: Boolean, default: false },
    light: { type: Boolean, default: false },
    bottom: { type: Boolean, default: false }
  },
  computed: {
    svg() {
      const shapeKey = Object.keys(blobs).some(key => key === this.shape) ? this.shape : 'a'
      return blobs[shapeKey]
    }
  }
}
</script>
