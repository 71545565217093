<template>
  <nav class="navbar">
    <aside class="navbar__main">
      <RouterLink :to="{ name: 'home' }">
        <img src="/images/logo.svg" alt="Ville Éponge" class="navbar__logo" />
      </RouterLink>

      <a :href="facebook.url" class="navbar__link -icon -thin" target="_blank" rel="noopener"
        >{{ facebook.linkLabel }}
        <Icon id="facebook" class="navbar__icon" />
      </a>
    </aside>

    <menu class="navbar__menu">
      <template v-if="$device.laptop">
        <RouterLink class="navbar__link" :to="{ name: 'program' }">
          <Icon id="leaf" class="navbar__link-leaf" />
          À propos
        </RouterLink>

        <RouterLink class="navbar__link" :to="{ name: 'accompaniment' }">
          <Icon id="leaf" class="navbar__link-leaf" />
          Accompagnement
        </RouterLink>

        <RouterLink class="navbar__link -involve button -link -flat -corner-right" :to="{ name: 'involve' }">
          S'impliquer
        </RouterLink>
      </template>

      <button class="burger" @click="$emit('burger-clicked')">
        <span class="burger__line"><Icon id="leaf" class="burger__leaf" /></span>
        <span class="burger__line -middle"><Icon id="leaf" class="burger__leaf" /></span>
        <span class="burger__line"><Icon id="leaf" class="burger__leaf" /></span>
      </button>
    </menu>
  </nav>
</template>

<script>
export default {
  name: 'NavBar',
  computed: {
    facebook() {
      return this.$nova.options.general.facebook
    }
  }
}
</script>
