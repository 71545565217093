<template>
  <div class="row">
    <div id="map" ref="map" class="map row__item"/>
    <aside :style="infoWindowWidth" class="info-window">
      <template v-if="selectedProject">
        <RouterLink
            :to="{ name: 'project', params: { projectSlug: selectedProject.slug } }"
            class="card -projects"
            :aria-labelledby="getAriaLabel(selectedProject.title)">
          <div class="card__content">
            <figure class="card__background" :style="getBackgroundImage(selectedProject.image)"/>
            <div class="card__inner">
              <div class="row -spread -align-center -mobile">
                <p class="card__status">
                  <Icon :id="selectedProject.status"/>
                  {{ getStatus(selectedProject.status) }}
                </p>
                <p class="card__category">
                  <Icon :id="`project-${selectedProject.category}`" class="category"/>
                  {{ getCategory(selectedProject.category) }}
                </p>
              </div>
              <h3 class="card__title">{{ selectedProject.title }}</h3>
              <p class="card__location">
                <Icon id="project-location"/>
                {{ selectedProject.region }}
              </p>
              <div class="card__inner-content" v-if="$device.tablet" >
                <p class="card__label">{{ labels.details }}</p>
                <div style="height: 80%" data-simplebar>
                  <p class="card__details">{{ selectedProject.infrastructures }}</p>
                  <p class="card__details">{{ selectedProject.area }}</p>
                  <p class="card__details">{{ selectedProject.projectBudget }}</p>
                </div>
              </div>

              <div class="link -after -selected-project">
                <span>{{ labels.info }}</span>
                <Icon id="leaves"/>
              </div>
            </div>
          </div>
        </RouterLink>
      </template>
    </aside>
  </div>
</template>

<script>
import SimpleBar from 'simplebar';
import 'simplebar/dist/simplebar.css';


import {categoryNames, statusNames} from '@/constants'

export default {
  name: 'GoogleMapViewport',
  props: {
    labels: {type: Object, required: true},
    projects: {type: Array, default: Array}
  },
  data() {
    return {
      googleMapsApiKey: process.env.VUE_APP_GOOGLE_MAP_KEY,
      isGoogleApiLoaded: !!(window.google && window.google.maps),
      markers: [],
      mapInstance: null,
      infoWindow: null,
      markerIcons: {
        eponge: '/images/marker-eponge.svg',
        epongeActive: '/images/marker-eponge-active.svg',
        other: '/images/marker-other.svg',
        otherActive: '/images/marker-other.svg'
      },
      selectedMarker: null
    }
  },
  computed: {
    infoWindowWidth() {
      if (this.selectedMarker && this.$device.tablet) {
        return {width: '24.5rem'}
      } else if (this.selectedMarker) {
        return {width: '90%'}
      }
      return {width: 0}
    },
    selectedProject() {
      if(this.selectedMarker) {
        const project = {...this.$nova.projects[this.selectedMarker]}
        project.medias.forEach(({image}) => {
          if(image) project.image = image
        })
        return project
      }
      return null
    }
  },
  watch: {
    projects: 'generateMarkers'
  },
  mounted() {
    this.$nextTick(() => {
      this.loadMap()
    })
    this.$nextTick(() => {
      const simplebar = new SimpleBar(document.getElementsByClassName('card__inner-content'))
      return simplebar
    })


  },
  methods: {
    getBackgroundImage(url) {
      return `background-image: url('${url}')`
    },
    getAriaLabel(projectTitle) {
      return `Plus d'infos sur ${projectTitle})`
    },
    getStatus(slug) {
      return statusNames[slug]
    },
    getCategory(slug) {
      return categoryNames[slug]
    },
    loadMap() {
      if (this.isGoogleApiLoaded) return this.generateMap()

      const googleMapsScript = document.createElement('script')
      googleMapsScript.src = `https://maps.googleapis.com/maps/api/js?key=${this.googleMapsApiKey}&id=d3439c3cc19ada4e&libraries=places`
      googleMapsScript.addEventListener('error', console.error)

      googleMapsScript.addEventListener('load', () => {
        if (!window.google || !window.google.maps)
          return console.error(new Error('Could not find global "google.maps"'))
        this.isGoogleApiLoaded = true
        this.generateMap()
      })

      document.head.appendChild(googleMapsScript)
    },
    generateMap() {
      const {maps} = window.google || {}

      this.mapInstance = new maps.Map(this.$refs.map, {
        center: {lat: 46.214, lng: -72.243},
        disableDefaultUI: true,
        zoomControl: 1,
        zoom: 8,
        minZoom: 2,
        maxZoom: 17,
        mapId: '8c71ec6b49782f5f'
      })

      this.generateMarkers()
    },
    generateMarkers() {
      const {maps} = window.google || {}
      this.markers.forEach(marker => marker.setMap(null))
      this.markers = []

      this.markers = this.projects.map(project => {
        const marker = new maps.Marker({
          icon: this.getMarkerIcon(project.isEponge),
          map: this.mapInstance,
          position: new maps.LatLng(project.latitude, project.longitude),
          title: project.title,
          id: project.id,
          isEponge: !!project.isEponge,
          optimized: false
        })

        marker.addListener('click', () => {
          this.selectedMarker = this.selectedMarker === project.id ? null : project.id
          this.updateMarkerIcons()
          if(this.$device.tablet) {
            this.mapInstance.panTo(marker.getPosition());
          } else {
            const divHeightOfTheMap = document.getElementById('map').clientHeight;
            const offSetFromBottom = 50;
            this.mapInstance.setCenter(marker.getPosition());
            this.mapInstance.panBy(0, -(divHeightOfTheMap / 2 - offSetFromBottom));
          }

        })
        return marker
      })
    },
    updateMarkerIcons() {
      this.markers.forEach(marker => {
        marker.setIcon(this.getMarkerIcon(marker.isEponge, marker.id === this.selectedMarker))
      })
    },
    getMarkerIcon(isEponge, isActive) {
      const projectCategory = isEponge ? 'eponge' : 'other'
      const iconKey = isActive ? `${projectCategory}Active` : projectCategory
      return this.markerIcons[iconKey]
    }
  }
}
</script>
