<template>
  <div v-if="!$page" />
  <div v-else id="home" class="home">
    <header class="header -home">
      <figure
        v-scroll:reveal="{ delay: 550 }"
        class="header__background"
        :style="{ backgroundImage: 'url(/images/home-header.svg)' }"
      />

      <div class="header__content -home">
        <HighlightedTitle v-scroll:reveal="{ delay: 550 }" :title="$page.header.title" class="header__title -home" />

        <WrapHtml v-scroll:reveal="{ delay: 600 }">{{ $page.header.description }}</WrapHtml>

        <nav v-scroll:reveal="{ delay: 650 }" class="header__nav">
          <RouterLink :to="{ name: 'themes' }" class="header__link button -link">{{ $page.header.button }}</RouterLink>

          <RouterLink :to="{ name: 'program' }" class="header__link button -link -inline -mobile-hidden -dark">
            <span>{{ $page.header.label }}</span>
            <Icon id="leaves" class="button__icon" />
          </RouterLink>
        </nav>

        <footer v-scroll:reveal="{ delay: 750, offset: 0 }" class="header__stats">
          <div v-for="(stat, statKey) in $page.header.items" :key="statKey" class="stat-box">
            <RouterLink :to="[{ name: 'projects' }, { name: 'program', hash: '#partenaires' }][statKey]">
              <output class="stat-box__output">{{ stat.title }} <em class="stat-box__suffix">+</em></output>
              <p>{{ stat.description }}</p>
            </RouterLink>
          </div>
        </footer>
      </div>
    </header>

    <div style="position: relative; margin-top: 10rem">
      <Blob shape="a" />
    </div>

    <section id="programme" class="section home__program row">
      <div class="home__program-row">
        <figure
          v-scroll:reveal="{ delay: 100 }"
          class="section__background home__program-background"
          :style="{ backgroundImage: 'url(/images/home-tools.svg)' }"
        />

        <Video mobile :video="$page.initiative.video" v-if="$page.initiative.video"/>

        <div class="row__item -span-8">
          <HighlightedTitle
            v-scroll:reveal="{ delay: 100 }"
            :title="$page.initiative.title"
            class="section__title -home"
          />

          <WrapHtml v-scroll:reveal="{ delay: 100 }">{{ $page.initiative.description }}</WrapHtml>

          <RouterLink v-scroll:reveal="{ delay: 100 }" :to="{ name: 'program' }" class="section__link">
            <span>{{ $page.initiative.label }}</span>
            <Icon id="leaves" />
          </RouterLink>
        </div>
      </div>

      <Video :video="$page.initiative.video" v-if="$page.initiative.video"/>
    </section>

    <section id="actions" class="section -centered home__actions">
      <HighlightedTitle
        v-scroll:reveal="{ delay: 100 }"
        :title="$page.action.title"
        class="section__title -centered -home"
      />
      <WrapHtml v-scroll:reveal="{ delay: 100 }" class="section__legend home__actions-text"
        >{{ $page.action.description }}
      </WrapHtml>

      <RouterLink v-scroll:reveal="{ delay: 100 }" :to="{ name: 'program', hash: '#actions' }" class="section__link">
        <span>{{ $page.action.label }}</span>
        <Icon id="leaves" />
      </RouterLink>

      <ul class="row -mobile home__actions__list">
        <li
          v-for="(item, idx) in $page.action.items"
          :key="idx"
          v-scroll:reveal="{ delay: idx * 75 }"
          class="home__actions__item"
        >
          <figure class="section__background -badge" :style="getBackgroundImage(item.image)" />
          <p class="home__actions__item__content">{{ item.name }}</p>
        </li>
      </ul>
    </section>

    <section id="outils" class="section row home__tools">
      <div class="row__item -span-7">
        <HighlightedTitle v-scroll:reveal="{ delay: 100 }" :title="$page.theme.title" class="section__title -home" />
        <p v-scroll:reveal="{ delay: 100 }" class="section__pad-right">{{ $page.theme.description }}</p>
        <RouterLink
          :to="{ name: 'themes' }"
          class="header__link button -link home__tools-button"
          style="margin-top: 41px"
        >
          {{ $page.theme.button }}
        </RouterLink>

        <div class="home__tools-list">
          <div
            v-for="(item, idx) in themes"
            :key="item.id"
            v-scroll:reveal="{ delay: idx * 100, offset: 0 }"
            class="home__tools-item"
          >
            <div class="home__tools-item-content">
              <RouterLink class="home__tools-item-link" :to="{ name: 'theme', params: { themeSlug: item.slug } }">
                {{ item.title }}
              </RouterLink>
              <Icon id="leaves" style="flex: 1" />
            </div>
          </div>
        </div>
      </div>

      <img src="/images/home-program.svg" class="home__tools-background" alt="" />
    </section>

    <section id="projets" class="section row -reverse home__projects">
      <Blob shape="b" />

      <div class="row__item -span-6 -skip-1">
        <HighlightedTitle v-scroll:reveal :title="$page.project.title" class="section__title -home" />
        <WrapHtml v-scroll:reveal>{{ $page.project.description }}</WrapHtml>

        <RouterLink v-scroll:reveal :to="{ name: 'projects' }" class="button -link home__projects__link"
          >{{ $page.project.button }}
        </RouterLink>
      </div>

      <figure
        v-scroll:reveal="{ delay: 200 }"
        class="home__projects-image"
        :style="{ backgroundImage: 'url(/images/CRE-Accueil_Map.png)' }"
      />
    </section>

    <section id="partenaires" class="section -mid-top row -align-end">
      <div class="row__item -span-6">
        <HighlightedTitle v-scroll:reveal="{ delay: 100 }" :title="$page.network.title" class="section__title -home" />
        <WrapHtml v-scroll:reveal="{ delay: 100 }">{{ $page.network.description }}</WrapHtml>
        <div class="home__tools-item-content">
          <RouterLink class="home__tools-item-link" :to="{ name: 'involve' }">{{ $page.network.label }}</RouterLink>
          <Icon id="leaves" style="flex: 1" />
        </div>
      </div>

      <Mockup v-scroll:reveal="{ delay: 200 }" class="section__background row__item -span-5 -skip-1" />
    </section>
  </div>
</template>

<script>
import { PageMetaMixin } from '@/mixins'
import { Blob, Mockup } from '@/components/graphics'
import { HighlightedTitle } from '@/components/partials'
import { Icon, WrapHtml } from '@/components/global'
import Video from "@/components/partials/Video.vue";

export default {
  name: 'Home',
  novaPage: 'home',
  components: {
    Video,
    Blob,
    HighlightedTitle,
    Icon,
    Mockup,
    WrapHtml
  },
  mixins: [PageMetaMixin],
  data() {
    return {
      titleRaw: 'Tous ensemble, ramenons *la nature* en ville'
    }
  },
  computed: {
    themes() {
      const { themes = [] } = this.$page || {}
      return themes.map(themeId => this.$nova.themes[themeId]).filter(Boolean)
    }
  },
  methods: {
    getBackgroundImage(url) {
      return `background-image: url('${url}')`
    },
    preventIconBreak(str) {
      const words = str.split(' ')
      const [lastWord] = words.splice(-1, 1)
      return [words.join(' '), lastWord]
    }
  }
}
</script>
