<template>
  <article class="card">
    <RouterLink
      :to="{ name: 'project', params: { projectSlug: project.slug } }"
      class="row card__link"
      :aria-label="getAriaLabel(project.title)"
    >
      <div class="card__medias">
        <figure
          v-if="project.isEponge"
          class="card__medias__badge"
          :style="getBackgroundImage($nova.options.general.badge)"
        />
        <figure class="card__medias__image" :style="getBackgroundImage(project.images[0])" />
      </div>
      <div class="card__content">
        <p class="card__status"><Icon :id="project.status" />{{ getStatus(project.status) }}</p>
        <h3 class="card__title">{{ project.title }}</h3>
        <div class="row -spread -align-center -mobile">
          <p class="card__category">{{ getCategory(project.category) }}</p>
          <p class="link -after">Plus d'infos<Icon id="leaves" /></p>
        </div>
      </div>
    </RouterLink>
  </article>
</template>

<script>
import { statusNames, categoryNames } from '@/constants'

export default {
  name: 'CardProject',
  props: {
    projectId: { type: Number, default: null },
    itemClass: { type: [String, Array, Object], default: Array }
  },
  computed: {
    project() {
      return this.$nova.projects[this.projectId]
    }
  },
  methods: {
    getBackgroundImage(url) {
      return `background-image: url('${url}')`
    },
    getAriaLabel(projectTitle) {
      return `Plus d'infos sur ${projectTitle}`
    },
    getStatus(slug) {
      return statusNames[slug]
    },
    getCategory(slug) {
      return categoryNames[slug]
    }
  }
}
</script>
