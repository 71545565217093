<template>
  <div class="slider">
    <div class="row slider__top">
      <h3 class="section__subtitle">{{ title }}</h3>
      <div class="slider__panel">
        <span class="active">{{ String(1).padStart(2, 0) }}</span
        ><span class="total">{{ String(slides.length).padStart(2, 0) }}</span>
      </div>
    </div>
    <ul class="slider__content -mobile">
      <li v-for="item in slides" :key="item.id" class="slider__content__item -mobile">{{ item.content }}</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'SliderMobileObj',
  props: {
    slides: { type: Array, default: Array },
    itemClass: { type: [String, Array, Object], default: Array },
    title: { type: String, default: null }
  }
}
</script>
