export const categoryNames = {
  residential: 'Résidentiel',
  municipal: 'Municipal',
  commercial: 'Commercial',
  industrial: 'Industriel',
  institutional: 'Institutionnel'
}
export const statusNames = {
  done: 'Terminé',
  progress: 'En cours'
}
// TODO update query string to use these values
export const ownerValues = {
  eponge: 'eponge',
  other: 'other'
}

export const distributionModesDict = {
  online: 'En ligne',
  classroom: 'En salle',
  hybrid: 'Hybride'
}

export const sponsorTypesDict = {
  owner: 'Propriétaire',
  creator: 'Concepteur',
  financial: 'Partenaire'
}

export const supportedExtension = ['doc', 'dwg', 'dwf', 'dxf', 'pdf', 'video', 'xls']
