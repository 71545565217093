import actions from './actions'
import mutations from './mutations'
import { SET_READY } from './constants'

export * from './constants'

export default (Vue, store, reduceData = d => d) => {
  const novaStore = {
    actions,
    mutations,
    getters: {
      isNovaLoading: ({ loadingStates }) => loadingStates.length > 0,
      globalNovaState: ({ customTypes, loadingStates, ...state }, { isNovaLoading: isLoading }) => ({
        isLoading,
        ...state,
        ...customTypes
      })
    },
    state: () => ({ pages: {}, customTypes: {}, options: {}, loadingStates: [], isDataReady: false })
  }

  store.registerModule('nova', novaStore)

  Object.defineProperties(Vue.prototype, {
    $nova: {
      get() {
        return store.getters.globalNovaState
      }
    }
  })

  Promise.all([store.dispatch('fetchCustomTypes', reduceData), store.dispatch('fetchOptions')]).then(() =>
    store.commit(SET_READY, true)
  )

  Vue.mixin({
    beforeCreate() {
      const { novaPage } = this.$options
      if (!novaPage) return
      if (!this.$options.computed) this.$options.computed = {}
      store.dispatch('fetchPage', novaPage)

      this.$options.computed.$page = function () {
        return this.$store.state.nova.pages[novaPage] || null
      }
    }
  })
}
