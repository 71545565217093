const { requestAnimationFrame } = window

export default Vue =>
  new Vue({
    name: 'ScrollHubVM',
    data() {
      return {
        scroll: 0,
        isQueued: false,
        scrollingElement: null,
        unbindHandlers: {}
      }
    },
    methods: {
      init(scrollingElement, scrollEventTarget) {
        if (this.scrollingElement) return
        const scrollEventElement = scrollEventTarget || scrollingElement
        this.scrollingElement = scrollingElement
        scrollEventElement.addEventListener('scroll', this.onScroll)
      },
      onScroll() {
        if (this.isQueued) return
        this.isQueued = true
        requestAnimationFrame(this.onUpdate)
      },
      onUpdate() {
        if (!this.isQueued) return
        this.scroll += 1
        this.isQueued = false
      },
      bind(uid, handler) {
        this.unbindHandlers[uid] = this.$watch('scroll', handler, { immediate: true })
      },
      unbind(uid) {
        if (this.unbindHandlers[uid]) {
          this.unbindHandlers[uid]()
        }
      },
      isUnique(uid) {
        return !this.unbindHandlers[uid]
      },
      triggerUpdate(delay = 0) {
        setTimeout(this.onUpdate, delay)
      }
    }
  })
