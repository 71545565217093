<template>
  <div id="page-not-found" class="page">
    <header class="header -column">
      <div class="header__content">
        <h1 v-scroll:reveal="{ delay: 550 }" class="header__title">Page 404</h1>
        <h2 v-scroll:reveal="{ delay: 600 }" class="header__subtitle">Cette page n'existe pas.</h2>

        <RouterLink :to="{ name: 'home' }" class="button -link">Retourner à l'accueil</RouterLink>
      </div>
    </header>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
  metaInfo: {
    title: '404 - Not Found'
  },
  beforeMount() {
    if (this.$route.name.includes('catch-all')) this.$router.replace({ name: 'not-found' })
  }
}
</script>
