<template>
    <div class="video" :class="{'-mobile': mobile}" @click="play = true" :style="{backgroundImage: `url(${thumb})`}">
      <img src="/images/player.png" alt="player" width="132" height="132" class="video__play"/>

      <transition name="fade">
        <div class="video__player" v-if="play" @click.stop="play = false">
          <div class="video__player-content" @click.stop>
            <iframe
                :src="`https://www.youtube.com/embed/${id}?autoplay=1`"
                frameborder="0"
                allow="autoplay"
                class="video__player-iframe">
            </iframe>
            <button class="button -corner-bottom-left" @click.stop="play = false">Fermer</button>
          </div>
        </div>
      </transition>
    </div>
</template>

<script>
export default {
  name: "Video",
  props: {
    mobile: {type: Boolean, default: false},
    image: {type: String, default: ''},
    video: {type: String, default: ''}
  },
  data() {
    return {
      play: false
    }
  },
  computed: {
    id() {
      return this.video.match(/v=([^&]+)/)[1]
    },
    thumb() {
      return `https://img.youtube.com/vi/${this.id}/hqdefault.jpg`
    }
  }
}
</script>
