<template>
  <main v-if="$nova.isDataReady" id="app" class="layout">
    <Transition name="page" mode="out-in" appear @after-leave="setScrollPosition" @enter="updateScrollPosition">
      <RouterView :key="$route.path" class="page layout__view" />
    </Transition>

    <GoogleSearchBar v-if="$device.tablet" />
    <NavBar @burger-clicked="isSideNavVisible = true" />
    <SiteFooter />

    <RouterLink :to="openContactPanelLink" :class="['bubble', '-contact', { '-hidden': $route.query.contact }]">
      <Icon id="contact" class="mail" />
    </RouterLink>

    <ContactPanel />
    <SideNav :is-visible="isSideNavVisible" @close="isSideNavVisible = false" />

    <ImageModal/>
  </main>
</template>

<script>
import { NavBar, SiteFooter, ContactPanel, SideNav } from '@/components'
import { GoogleSearchBar } from '@/components/googleServices'
import ImageModal from "@/components/partials/ImageModal.vue";

export default {
  name: 'App',
  components: {ImageModal, NavBar, SiteFooter, ContactPanel, SideNav, GoogleSearchBar },
  metaInfo: {
    title: 'Ville éponge',
    titleTemplate: '%s | Ville éponge'
  },
  data() {
    return {
      scrollPosition: 0,
      isSideNavVisible: false
    }
  },
  computed: {
    openContactPanelLink() {
      return {
        name: this.$route.name,
        query: { ...this.$route.query, contact: true }
      }
    }
  },
  watch: {
    $route({ name, hash }, from) {
      if (name !== from.name || !hash) return
      const delay = this.$device.tablet ? 0 : 150
      return setTimeout(() => this.$scroll.toTarget(hash, 80, 350), delay)
    }
  },
  methods: {
    updateScrollPosition() {
      this.$nextTick(() => {
        const { hash } = this.$route
        if (hash) return setTimeout(() => this.$scroll.toTarget(hash, 80, 350), 150)
        this.$scroll.to(this.scrollPosition)
      })
    },
    setScrollPosition() {
      this.scrollPosition = window.savedScroll || 0
      this.$scroll.to(0)
    }
  }
}
</script>
