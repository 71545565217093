const { requestAnimationFrame } = window
const nodes = {}

let options = {
  offset: 0,
  delay: 0,
  activeClassName: 'reveal',
  startClassName: 'reveal-start',
  endClassName: 'reveal-end'
}

export default ScrollHub => {
  const createTransitionEndHandler = node => {
    const onTransitionEnd = () => {
      node.classList.remove(options.activeClassName, options.endClassName)
      node.removeEventListener('transitionend', onTransitionEnd)
    }

    return onTransitionEnd
  }

  const update = () => {
    Object.keys(nodes).forEach(uid => {
      const { node, trigger, delay } = nodes[uid]

      if (node.getBoundingClientRect().top < trigger) {
        node.addEventListener('transitionend', createTransitionEndHandler(node))

        setTimeout(() => {
          node.classList.add(options.activeClassName)

          requestAnimationFrame(() => {
            node.classList.replace(options.startClassName, options.endClassName)
            delete nodes[uid]
          })
        }, delay)
      }
    })
  }

  ScrollHub.bind('reveal', update)

  return {
    init({ uid, node }) {
      node.classList.add(options.startClassName)
      node.dataset.scrollId = uid
    },
    add({ uid, node, value = {} }) {
      const { offset = options.offset, delay = options.delay } = value
      const trigger = window.innerHeight - offset
      nodes[uid] = { node, delay, trigger }
      setTimeout(update, 100)
    },
    remove(uid) {
      if (nodes[uid]) delete nodes[uid]
    },
    setOptions({ reveal = {} }) {
      const {
        offset = options.offset,
        delay = options.delay,
        activeClassName = options.activeClassName,
        startClassName = options.startClassName,
        endClassName = options.endClassName
      } = reveal

      options = { offset, delay, activeClassName, startClassName, endClassName }
    }
  }
}
